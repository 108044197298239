import { RSAA } from 'redux-api-middleware'

export const pageNewsArticleConsts = {
  REQUEST: '@@PAGE_NEWS_ARTICLE/PAGE_NEWS_ARTICLE_REQUEST',
  SUCCESS: '@@PAGE_NEWS_ARTICLE/PAGE_NEWS_ARTICLE_SUCCESS',
  FAILURE: '@@PAGE_NEWS_ARTICLE/PAGE_NEWS_ARTICLE_FAILURE',
};

export const fetchNewsArticle = (id) => ({
  [RSAA]: {
    endpoint: `/api/v2/news/${id}/?format=json`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageNewsArticleConsts.REQUEST,
      pageNewsArticleConsts.SUCCESS,
      pageNewsArticleConsts.FAILURE
    ]
  }
})
