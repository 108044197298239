import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { NewsfeedBreeding, IconName } from '../Header/iconSvgs'

const NewsFeedList = (props) => {
  return (
    <section className="newsfeed-list">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col col-sm-11 col-md-10 col-lg-8">
            {props.data && props.data.map((item, idx) => {
              return (
                <Link key={idx} to={`newsfeed/article?id=${item.id}`} className="newsfeed-list_item">
                  {item.newsImage &&
                    <div className="newsfeed-list_img-wrap">
                      <img src={item.newsImage} className="newsfeed-list_img" />
                    </div>
                  }
                  <div className="newsfeed-list_text-wrap">
                    {item.title &&
                      <div className="newsfeed-list_title-wrap">
                        <h2 className="newsfeed-list_title">{item.title}</h2>
                      </div>
                    }
                    {item.entryType !== 'NEWS' &&
                      <div className="d-flex flex-column align-items-center newsfeed-list_title-wrap">
                        {item.entryType === 'BIRTH' && <NewsfeedBreeding />}
                        {item.entryType === 'NEW NAME' && <IconName />}
                        <h2 className="newsfeed-list_title">
                          {item.entryType === 'BIRTH' && 'Birth Announcement'}
                          {item.entryType === 'NEW NAME' && 'New Name Announcement'}
                        </h2>
                      </div>
                    }
                    <div className="newsfeed-list_body" dangerouslySetInnerHTML={{ __html: item.intro }}></div>
                    <div className="d-flex justify-content-between">
                      <div className="d-inline-flex align-items-end newsfeed-list_date">{moment(item.newsDate).format('MMM D[,] YYYY')}</div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsFeedList