import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { fetchHorsesAll } from '../../_actions/page-horses.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import PredictiveSearch from '../../_components/PredictiveSearch'
import CurrentHorsesTable from '../../_components/CurrentHorsesTable'
import LoadingSplash from '../../_components/LoadingSplash'

class HorsesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTableData: [],
      activeSearchTerm: null,
      isLoading: true,
      isMobile: window.matchMedia('(max-width: 767px)').matches
    }

    this.searchRef = React.createRef()
    this.tableRef = React.createRef()
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 767px)').matches
    })
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.checkIfMobile)

    if (
      this.props.pageHorsesData.all.needsInit ||
      this.props.pageHorsesData.all.error
      ) {
      await this.props.dispatch(fetchHorsesAll())
    }

    if (this.props.pageHorsesData.all.error) {
      if (this.props.pageHorsesData.all.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  setActiveSearchTerm(activeSearchTerm) {
    this.setState({
      activeSearchTerm
    })
  }

  resetActiveSearchTerm() {
    this.setState({
      activeSearchTerm: null
    })
    this.tableRef.current.resetTableFromTab()
  }

  resetTableFromTab() {
    this.tableRef.current.resetTableFromTab()
  }

  setActiveTableData(activeTableData) {
    this.setState({
      activeTableData
    })
  }

  signalTabChange() {
    this.searchRef.current.resetPredictions()
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Horses'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Horses'} />
            <PredictiveSearch
              ref={this.searchRef}
              activeTableData={this.state.activeTableData}
              setActiveSearchTerm={this.setActiveSearchTerm.bind(this)}
              resetActiveSearchTerm={this.resetActiveSearchTerm.bind(this)}
              resetTableFromTab={this.resetTableFromTab.bind(this)}
            />
            <CurrentHorsesTable
              ref={this.tableRef}
              tableData={this.props.pageHorsesData.all}
              setActiveTableData={this.setActiveTableData.bind(this)}
              signalTabChange={this.signalTabChange.bind(this)}
              activeSearchTerm={this.state.activeSearchTerm}
              isMobile={this.state.isMobile}
            />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageHorsesData } = state
  return {
    pageHorsesData
  }
}

export default connect(mapStateToProps)(HorsesPage)