import React, { Component } from 'react'

import iconArrowDown from '../../_img/icon-arrow-down.svg'
import iconClose from '../../_img/icon-close.svg'

class CheckboxFilters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filtersOpen: false
    }

    this.maxFilterLength = null
  }

  componentDidMount() {
    if (Object.keys(this.props.tableFiltersData).length) {
      let lengths = []
      for (let key in this.props.tableFiltersData) {
        lengths.push(this.props.tableFiltersData[key].length)
      }
      this.maxFilterLength = Math.max(...lengths)
    }
  }

  toggleFilters() {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    }, () => {
      if (!this.state.filtersOpen) {
        this.props.resetFilters()
      }
    })
  }

  handleFiltersApply() {
    let activeFilters = {}

    const filterRows = document.querySelectorAll('.checkbox-filters_filters-row:not(.is-empty) > .row')

    filterRows.forEach((elem, idx) => {
      let checkboxes = elem.querySelectorAll('.login-form_checkbox')
      const rowKey = elem.getAttribute('data-filter-cat')
      activeFilters[rowKey] = []
      for (let i = 0, len = checkboxes.length; i < len; i++) {
        if (checkboxes[i].checked) {
          activeFilters[rowKey].push(checkboxes[i].getAttribute('id'))
        }
      }
    })

    this.props.setActiveFilters(activeFilters)
  }

  filterRowsMap(key, idx) {
    const item = this.props.tableFiltersData[key]
    let filtersWrap = []
    let filters = []
    for (let i = 0; i < this.maxFilterLength; i++) {
      if (item[i] === undefined) { break; }
      if (i === 0) {
        filtersWrap.push(<div className="checkbox-filters_category-title">{item[i]}</div>)
      } else {
        filters.push(
          <div key={i} className={`col${i === 0 ? ' checkbox-filters_label' : ''}`}>
            <div className="login-form_checkbox-wrap">
              <input type="checkbox" className="login-form_checkbox" id={item[i].replace(' ', '')} />
              <label htmlFor={item[i].replace(' ', '')}></label>
              <div className="login-form_checkbox-label">{item[i]}</div>
            </div>
          </div>
        )
      }
    }
    filtersWrap.push(<div key={idx} className="row no-gutters flex-column flex-md-row" data-filter-cat={key}>{filters}</div>)
    return React.createElement('div', { className: 'row no-gutters flex-column flex-md-row checkbox-filters_filters-row' }, filtersWrap)
  }

  render() {
    return (
      <section className="checkbox-filters">
        <div className="container-fluid">
          <button onClick={this.toggleFilters.bind(this)} type="button" className="checkbox-filters_main-tab d-inline-flex align-items-center">
            <div className="checkbox-filters_label d-inline-flex align-items-center">
              Filter
            </div>
            <img src={this.state.filtersOpen ? iconClose : iconArrowDown} />
          </button>
          {this.state.filtersOpen &&
            <div className="checkbox-filters_checkboxes-wrap">
              {Object.keys(this.props.tableFiltersData).map(this.filterRowsMap.bind(this))}
              <div className="row no-gutters justify-content-end checkbox-filters_filters-row is-empty">
                <button onClick={this.handleFiltersApply.bind(this)} type="button" className="checkbox-filters_apply-btn">Apply</button>
              </div>
            </div>
          }
        </div>
      </section>
    )
  }
}

export default CheckboxFilters