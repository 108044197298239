import React from 'react'

export default function ViewPDFBtn(props) {
  return (
    <div className="events-clndr_view-pdf-btn-wrap">
      {props.label && <div className="events-clndr_additional-label">{props.label}</div>}
      <a onClick={props.authFunction ? props.authFunction : () => null} href={`${props.PDF ? props.PDF : 'javascript:void(0)'}`} className={`events-clndr_view-pdf-btn${!props.PDF && !props.authFunction ? ' is-disabled' : ''}`} target="_blank">
        <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-891.000000, -454.000000)" className="events-clndr_view-pdf-fill">
              <g transform="translate(522.000000, 287.000000)">
                <g transform="translate(330.000000, 122.000000)">
                  <g transform="translate(0.000000, 23.000000)">
                    <path d="M61,23.625 C61,22.727194 60.2967794,22 59.4285714,22 L40.5714286,22 C39.7032206,22 39,22.727194 39,23.625 L39,46.375 C39,47.272806 39.7032206,48 40.5714286,48 L59.4285714,48 C60.2967794,48 61,47.272806 61,46.375 L61,23.625 Z M43.7142857,26.875 L50,26.875 L50,28.5 L43.7142857,28.5 L43.7142857,26.875 Z M56.2857143,43.125 L43.7142857,43.125 L43.7142857,41.5 L56.2857143,41.5 L56.2857143,43.125 Z M56.2857143,38.25 L43.7142857,38.25 L43.7142857,36.625 L56.2857143,36.625 L56.2857143,38.25 Z M56.2857143,33.375 L43.7142857,33.375 L43.7142857,31.75 L56.2857143,31.75 L56.2857143,33.375 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div className="events-clndr_view-pdf-label text-center">View PDF</div>
      </a>
    </div>
  )
}