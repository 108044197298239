import { RSAA } from 'redux-api-middleware'

export const pageLocationTallyConsts = {
  REQUEST: '@@PAGE_LOCATION_TALLY/PAGE_LOCATION_TALLY_REQUEST',
  SUCCESS: '@@PAGE_LOCATION_TALLY/PAGE_LOCATION_TALLY_SUCCESS',
  FAILURE: '@@PAGE_LOCATION_TALLY/PAGE_LOCATION_TALLY_FAILURE',
};

export const pageLocationFiltersConsts = {
  REQUEST: 'PAGE_LOCATION_FILTERS_REQUEST',
  SUCCESS: 'PAGE_LOCATION_FILTERS_SUCCESS',
  FAILURE: 'PAGE_LOCATION_FILTERS_FAILURE',
};

export const pageLocationTableConsts = {
  REQUEST: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_REQUEST',
  SUCCESS: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_SUCCESS',
  FAILURE: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_FAILURE',
};

export const pageLocationTableCwConsts = {
  REQUEST: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_CW_REQUEST',
  SUCCESS: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_CW_SUCCESS',
  FAILURE: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_CW_FAILURE',
};

export const pageLocationTableNotCwConsts = {
  REQUEST: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_NOT_CW_REQUEST',
  SUCCESS: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_NOT_CW_SUCCESS',
  FAILURE: '@@PAGE_LOCATION_TABLE/PAGE_LOCATION_TABLE_NOT_CW_FAILURE',
};

export const fetchLocationTally = () => ({
  [RSAA]: {
    endpoint: `/api/v2/horseSummaryByLocation/`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLocationTallyConsts.REQUEST,
      pageLocationTallyConsts.SUCCESS,
      pageLocationTallyConsts.FAILURE
    ]
  }
})

export const fetchLocationFilters = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: pageLocationFiltersConsts.REQUEST })

      let payload = await Promise.all([
        fetch('/api/v2/refdata/location', {
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
          }  
        })
        .then(async res => await res.json()),
        fetch('/api/v2/refdata/trainer', {
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authorization_token')}`
          }   
        })
        .then(async res => await res.json()),
          fetch('/api/v2/refdata/country', {     
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authorization_token')}`
          }  
        })
        .then(async res => await res.json()),
        ])

      const filters = {
        location: ['Location', ...payload[0].items.map(item => (item.location))],
        inCareOf: ['In Care Of', ...payload[1].items.map(item => (item.trainer))],
        country: ['Country', ...payload[2].items.map(item => (item.country))],
      }

      dispatch({
        type: pageLocationFiltersConsts.SUCCESS,
        payload: filters
      })
    } catch (e) {
      dispatch({
        type: pageLocationFiltersConsts.FAILURE,
        error: e
      })
    }
  }
}

export const fetchLocationTable = () => ({
  [RSAA]: {
    endpoint: `/api/v2/horse/Location`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLocationTableConsts.REQUEST,
      {
        type: pageLocationTableConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          let tableData = {
            tableHeadings: ['Horse', 'Year', 'Colour', 'Gender', 'Sire', 'Dam'],
            tableEntities: []
          }
          payload.items.forEach(item => {
            let entity = {
              detailID: item.horseId,
              tableCells: [
                item.horse,
                item.birthYear,
                item.color,
                item.gender,
                item.sire,
                item.dam
              ],
              filters: {
                location: item.location,
                inCareOf: item.inCareOf,
                country: item.country
              }
            }
            tableData.tableEntities.push(entity)
          })
          return tableData
        }
      },
      pageLocationTableConsts.FAILURE
    ]
  }
})

export const fetchLocationTableCw = () => ({
  [RSAA]: {
    endpoint: `/api/v2/horse/cwLocation`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLocationTableCwConsts.REQUEST,
      {
        type: pageLocationTableCwConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          const headings = "tableCwData";

          let tableData = {
            tableHeadings : ['Horse', 'Gender', 'Colour', 'Sire', 'Dam', 'Status'],
            tableEntities: []
          }

          payload.items.forEach(item => {

            let horseList = [];
            item.horseList.forEach(horse => {
              let entity = {
                headings,
                detailId: horse.horseID,
                tableCells: [
                  horse.horse,
                  horse.gender,
                  horse.color,
                  horse.sire,
                  horse.dam,
                  horse.status,

                ],
                filters: {
                  location: horse.location,
                  inCareOf: horse.inCareOf,
                  country: horse.country
                }
              }
              horseList.push(entity)
          })
            
            let entity = {
              headings,
              location: item.location,
              inCareOf: item.trainer,
              count: item.total,
              table: horseList
            }
            tableData.tableEntities.push(entity)
          })
          return tableData
        }
      },
      pageLocationTableCwConsts.FAILURE
    ]
  }
})

export const fetchLocationTableNotCw = () => ({
  [RSAA]: {
    endpoint: `/api/v2/horse/notCwLocation`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLocationTableNotCwConsts.REQUEST,
      {
        type: pageLocationTableNotCwConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          const headings = "tableNotCwData";

          let tableData = {
            tableHeadings : ['Horse', 'Gender', 'Colour', 'Sire', 'Dam', 'Location'],
            tableEntities: []
          }

          payload.items.forEach(item => {

            let horseList = [];
            item.horseList.forEach(horse => {
              let entity = {
                headings,
              detailId: horse.horseID,
              tableCells: [
                horse.horse,
                horse.gender,
                horse.color,
                horse.sire,
                horse.dam,
                horse.location,

              ],
              filters: {
                location: horse.location,
                inCareOf: horse.inCareOf,
                country: horse.country
              }
            }

            
            horseList.push(entity)
          })
            
            let entity = {
              headings,
              inCareOf: item.trainer,
              count: item.total,
              table: horseList
            }
            tableData.tableEntities.push(entity)
          })
          return tableData
        }
      },
      pageLocationTableNotCwConsts.FAILURE
    ]
  }
})