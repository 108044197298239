import { RSAA } from 'redux-api-middleware'
import moment from 'moment'

export const pageDetailConsts = {
  REQUEST: '@@PAGE_DETAIL/PAGE_DETAIL_REQUEST',
  SUCCESS: '@@PAGE_DETAIL/PAGE_DETAIL_SUCCESS',
  FAILURE: '@@PAGE_DETAIL/PAGE_DETAIL_FAILURE',
}

export const fetchHorseDetail = (id) => ({
  [RSAA]: {
    endpoint: `/api/v2/horses/${id}/?format=json`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageDetailConsts.REQUEST,
      {
        type: pageDetailConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          //format racing stats
          let currIdx = 0
          let yearsIdxs = {}
          let racingStatsByYear = []

          payload.racingStats.items.forEach(item => {
            const itemYear = item.raceYear.toString()
            if (!yearsIdxs.hasOwnProperty(itemYear)) {
              racingStatsByYear[currIdx] = { year: itemYear, items: [] }
              yearsIdxs[itemYear] = currIdx++
            }
            racingStatsByYear[yearsIdxs[itemYear]].items.push(item)
          })

          let racingStatsData = {
            tableHeadings: ['Year', 'Age', 'Starts', '1st', '2nd', '3rd', 'Earnings'],
            tableEntities: []
          }

          const tallyIdxs = {
            1: 3,
            2: 4,
            3: 5,
            finishPlaceEarning: 6
          }

          var totalEntity = {
            tableCells: ['Totals', '-', 0, '-', '-', '-', 0]
          }

          racingStatsByYear.forEach((item, idx, arr) => {
            let entity = {
              tableCells: []
            }

            item.items.forEach((innerItem, idx, arr) => {
              if (idx === 0) {
                entity.tableCells =
                  [innerItem.raceYear, innerItem.age, 0, '-', '-', '-', 0]
              }

              entity.tableCells[2] += innerItem.raceCount
              totalEntity.tableCells[2] += innerItem.raceCount

              if (entity.tableCells[tallyIdxs[innerItem.raceFinishPlace]] === '-') {
                entity.tableCells[tallyIdxs[innerItem.raceFinishPlace]] = innerItem.raceCount

                totalEntity.tableCells[tallyIdxs[innerItem.raceFinishPlace]] === '-'
                  ? totalEntity.tableCells[tallyIdxs[innerItem.raceFinishPlace]] = innerItem.raceCount
                  : totalEntity.tableCells[tallyIdxs[innerItem.raceFinishPlace]] += innerItem.raceCount
              }

              entity.tableCells[tallyIdxs.finishPlaceEarning] += innerItem.finishPlaceEarning
              totalEntity.tableCells[tallyIdxs.finishPlaceEarning] += innerItem.finishPlaceEarning
            })

            entity.tableCells = entity.tableCells.slice(0, 7);

            entity.tableCells[tallyIdxs.finishPlaceEarning]
              = (entity.tableCells[tallyIdxs.finishPlaceEarning]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

            racingStatsData.tableEntities.push(entity)

            if (idx === arr.length - 1) {
              totalEntity.tableCells[tallyIdxs.finishPlaceEarning]
                = (totalEntity.tableCells[tallyIdxs.finishPlaceEarning]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
              racingStatsData.tableEntities.push(totalEntity)
            }
          })

          payload.racingStats = racingStatsData

          //format produce record
          let produceRecordData = {
            tableHeadings: ['Year', 'Horse', 'Gender', 'Sire', 'Status', 'CDN', 'USD'],
            tableEntities: []
          }

          payload.get_all_children.items.forEach(item => {
            let entity = {
              detailID: item.foalId,
              tableCells: [
                item.year ? item.year : '-',
                item.name ? item.name : '-',
                item.gender ? item.gender : '-',
                item.sire ? item.sire : '-',
                item.status ? item.status : '-',
                (item.earning && item.earning.items.length >= 1) ? item.earning.items[0].earningTtl : 0,
                (item.earning && item.earning.items.length >= 2) ? item.earning.items[1].earningTtl : 0
              ],
              note: item.note
            }
            produceRecordData.tableEntities.push(entity)
          })

          payload.produceRecord = produceRecordData

          //format racing result
          let racingResults = {}
          racingResults.tableEntities = []

          payload.racingResult.forEach((item) => {
            let entity = {
              main: {
                tableHeadings: [
                  payload.name, 'Date', 'Distance', 'Jockey', 'Finish', 'Race Condition', 'Trainer'
                ],
                tableCells: [
                  item.track,
                  moment(item.raceDate).format('MMM D[,] YYYY'),
                  item.distance,
                  item.jockey,
                  !item.finishPlace ? '-' : item.finishPlace,
                  item.condition,
                  item.trainer
                ]
              },
              details: {
                tableHeadings: [
                  'Comment', 'Race #', 'Surface', 'Post Race', 'Time', 'Earnings'
                ],
                tableCells: [
                  item.comment,
                  item.raceNumber,
                  item.surface,
                  item.postRaceDoc,
                  item.time,
                  item.earning
                ]
              }
            }
            racingResults.tableEntities.push(entity)
          })
          payload.racingResults = racingResults

          return payload
        }
      },
      pageDetailConsts.FAILURE
    ]
  }
})

