import React, { Component } from 'react'
import { Default, Mobile } from '../_mediaqueries'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/css/swiper.css'

const swiperParams = {
  slidesPerView: 2.8,
  preventClicks: true,
  slidesOffsetBefore: 15,
  slidesOffsetAfter: 15,
  slideToClickedSlide: true,
  shouldSwiperUpdate: true,
}

class TableTabs extends Component {
  tabsMap(item, idx, arr) {
    return (
      <button key={item} data-horse-state={item.toLowerCase()} type="button"
        onClick={this.handleTabClick.bind(this)}
        className={`table-tabs_year-tab${this.props.currentTab === item.toLowerCase() ? ' is-active' : ''}`}>
        {item}
      </button>
    )
  }

  handleTabClick(e) {
    this.props.setCurrentTab(e.target.getAttribute('data-horse-state'))
  }

  render() {
    return (
      <div className="container-fluid table-tabs">
        <Default>
          <div className="row table-tabs_row-1">
            <div className="col d-inline-flex justify-content-between justify-content-sm-around w-100">
              {this.props.horseStates && this.props.horseStates.map(this.tabsMap.bind(this))}
            </div>
          </div>
        </Default>
        <Mobile>
          <div className="table-tabs_row-1 fade-left-right">
            {this.props.horseStates &&
              <Swiper {...swiperParams}>
                {this.props.horseStates.map(this.tabsMap.bind(this))}
              </Swiper>
            }
          </div>
        </Mobile>
        <Default>
          <hr className="table-tabs_border-bot" />
        </Default>
      </div>
    )
  }
}

export default TableTabs