import { RSAA } from 'redux-api-middleware'
import moment from 'moment'

export const pageBreedingServicingConsts = {
  REQUEST: '@@PAGE_BREEDING_SERVICING/PAGE_BREEDING_SERVICING_REQUEST',
  SUCCESS: '@@PAGE_BREEDING_SERVICING/PAGE_BREEDING_SERVICING_SUCCESS',
  FAILURE: '@@PAGE_BREEDING_SERVICING/PAGE_BREEDING_SERVICING_FAILURE',
}

export const pageBreedingBirthsConsts = {
  REQUEST: '@@PAGE_BREEDING_BIRTHS/PAGE_BREEDING_BIRTHS_REQUEST',
  SUCCESS: '@@PAGE_BREEDING_BIRTHS/PAGE_BREEDING_BIRTHS_SUCCESS',
  FAILURE: '@@PAGE_BREEDING_BIRTHS/PAGE_BREEDING_BIRTHS_FAILURE',
}

export const fetchBreedingServicing = () => ({
  [RSAA]: {
    endpoint: '/api/v2/service/all',
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageBreedingServicingConsts.REQUEST,
      {
        type: pageBreedingServicingConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          let eventsData = []
          const additionalInfoLabels = ['Status', 'Sire', 'Last Serviced', 'Expected Date']
          payload.items.forEach((item) => {
            let entity = {
              start: item.serviceDate,
              end: item.serviceDate,
              title: item.horse,
              PDFs: item.moreInfo,
              additionalInfoLabels,
              additionalInfoData: [item.status, item.sire, item.lastServicedDate, item.estimatedDate]
            }
            eventsData.push(entity)
          })

          return eventsData
        }
      },
      pageBreedingServicingConsts.FAILURE
    ]
  }
})


export const fetchBreedingBirths = () => ({
  [RSAA]: {
    endpoint: `/api/v2/service/birth`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageBreedingBirthsConsts.REQUEST,
      {
        type: pageBreedingBirthsConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          const minYear = 1993
          const currentYear = (new Date).getFullYear()
          let tableData = {}
          tableData.tableHeadings = ['Horse', 'Dam', 'Sire', 'Serviced', 'Birth', 'Gender', 'Colour']
          tableData.years = []
          tableData.currentTab = currentYear.toString()
          tableData.tableEntities = []
          payload.items.forEach((item) => {
            let entity = {
              tableCells: [
                item.foal,
                item.dam,
                item.sire,
                `${moment(item.serviceDate).format('MMM D[,] YYYY')}`,
                !item.birthDate ? `${moment(item.estimatedDate).format('MMM D[,] YYYY')}` : `${moment(item.birthDate).format('MMM D[,] YYYY')}`,
                item.gender,
                item.colour
              ],
              detailID: item.foalId,
              note: item.notes,
              year: !item.birthDate ? item.estimatedDate.split('-')[0] : item.birthDate.split('-')[0],
              isEstimatedDate: !!item.estimatedDate,
            }
            tableData.tableEntities.push(entity)
          })

          for (let i = currentYear + 2; i >= minYear; i--) {
            tableData.years.push(i.toString())
          }

          return tableData
        }
      },
      pageBreedingBirthsConsts.FAILURE
    ]
  }
})

