import React, { Component } from 'react'
import { removeTime } from '../_utils'
import moment from 'moment'

import ViewPDFBtn from './ViewPDFBtn'

import arrowRight from '../../_img/icon-arrow-right.svg'
import close from '../../_img/icon-close.svg'

class EventsCalendarList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasReceivedEvents: false,
      additionalEvent: null
    }

    this.listRef = React.createRef()
    this.additionalRef = React.createRef()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.additionalEvent !== null && prevState.additionalEvent === null) {
      return this.listRef.current.scrollTop
    }
    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.hasReceivedEvents === false && this.props.events.length) {
      this.setState({
        hasReceivedEvents: true
      })
    }
    if (this.props.calendarSelectedEventId !== prevProps.calendarSelectedEventId) {
      this.setState({
        additionalEvent: this.props.events[this.props.calendarSelectedEventId]
      })
    }
    if (snapshot !== null && this.state.additionalEvent !== null && prevState.additionalEvent === null) {
      this.additionalRef.current.style.top = snapshot + 'px'
      this.listRef.current.style.overflowY = 'hidden'
    }
    if (this.state.additionalEvent === null && prevState.additionlEvent !== null) {
      this.listRef.current.style.overflowY = 'scroll'
    }
  }

  handleEventClick(e) {
    const target = e.currentTarget
    const eventId = target.getAttribute('data-id')

    this.showAdditional(eventId)

    this.props.setSelectedDate(eventId)
    this.props.setSelectedEvent(eventId)
  }

  showAdditional(eventId) {
    this.setState({
      additionalEvent: this.props.events[eventId]
    })
  }

  closeAdditional() {
    this.setState({
      additionalEvent: null
    })
  }

  setAllFilterActive(state) {
    this.props.setAllFilterActive()
  }

  eventsMap(evtItem, idx, evtArr) {
    if (idx > 0) {
      var isChildEvt = removeTime(evtItem.start) === removeTime(evtArr[idx - 1].start)
    }

    if (this.props.activeDate) {
      if (
        moment(this.props.activeDate).month() !== moment(removeTime(evtItem.start)).month() ||
        moment(this.props.activeDate).year() !== moment(removeTime(evtItem.start)).year()
      ) {
        return null
      }
    }

    return (
      <div key={evtItem.reactID} data-id={evtItem.reactID} onClick={this.handleEventClick.bind(this)}
        className={isChildEvt ? "events-clndr_event-item is-child-event" : "events-clndr_event-item"}
      >
        <div className="events-clndr_top-border"></div>
        <div className="d-flex align-items-center">
          <div className={`events-clndr_date-wrap
          ${(moment(removeTime(evtItem.start)).startOf('day') / 1000).toFixed(0).slice(0, 7) === (moment().startOf('day').toDate().getTime() / 1000).toFixed(0).slice(0, 7)
              ? ' is-today' : ''}`}>
            <div>{moment(evtItem.start).format('ddd')}</div>
            <div>{moment(evtItem.start).format('D')}</div>
          </div>
          <div className="events-clndr_text-wrap flex-grow-1">
            <div className="events-clndr_info"><h4>{evtItem.title}</h4> | <p>{evtItem.detail ? evtItem.detail : evtItem.additionalInfoData[1]}</p></div>
          </div>
          <img src={arrowRight} className="events-clndr_list-icon" />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div ref={this.listRef} className="events-clndr_events-list">
        {this.props.events.length > 0 && this.props.events.map(this.eventsMap.bind(this))}
        {this.props.events.length === 0 ? <div className="events-clndr_no-events-msg">No events found for selected month</div> : null}

        {this.state.additionalEvent !== null &&
          <div ref={this.additionalRef} className="events-clndr_event-additional">
            <div className="events-clndr_additional-item">
              <div className="d-flex align-items-center">
                <div className={`events-clndr_date-wrap
            ${(moment(removeTime(this.state.additionalEvent.start)).startOf('day') / 1000).toFixed(0).slice(0, 7) === (moment().startOf('day').toDate().getTime() / 1000).toFixed(0).slice(0, 7)
                    ? ' is-today' : ''}`}>
                  <div>{moment(this.state.additionalEvent.start).format('ddd')}</div>
                  <div>{moment(this.state.additionalEvent.start).format('D')}</div>
                </div>
                <div className="events-clndr_text-wrap flex-grow-1">
                  <div className="events-clndr_info"><h4>{this.state.additionalEvent.title}</h4> | <p>{this.state.additionalEvent.detail ? this.state.additionalEvent.detail : this.state.additionalEvent.additionalInfoData[1]}</p></div>
                </div>
                <img src={close} className="events-clndr_list-icon" onClick={this.closeAdditional.bind(this)} />
              </div>
            </div>

            <div className="row no-gutters flex-column flex-md-row align-items-start align-items-md-stretch events-clndr_additional-row">
              <div className="events-clndr_additional-top-border"></div>
              <hr className="d-md-none" />
              <div className="col events-clndr_additional-col-1">
                {this.state.additionalEvent.additionalInfoLabels.map((item, idx, arr) => {
                  return (
                    <div key={idx}>
                      <div className="events-clndr_additional-label">{item}</div>
                      <div className="events-clndr_additional-info">
                        {(idx === arr.length - 1 && this.props.location == 'home') && <span className="dollar-sign-margin">$</span>}
                        {this.state.additionalEvent.additionalInfoData[idx]}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="col-auto col-md-4 d-flex flex-row flex-md-column justify-content-around justify-content-md-center events-clndr_additional-col-2">
                {Object.prototype.hasOwnProperty.call(this.state.additionalEvent, 'preRacePDF') || Object.prototype.hasOwnProperty.call(this.state.additionalEvent, 'postRacePDF') ?
                  <React.Fragment>
                    <ViewPDFBtn label={'Pre-race'} PDF={this.state.additionalEvent.preRacePDF} />
                    <ViewPDFBtn label={'Post-race'} PDF={this.state.additionalEvent.postRacePDF} />
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {this.state.additionalEvent.PDFs.length ? this.state.additionalEvent.PDFs.map((item, idx) => (
                      <ViewPDFBtn key={idx} PDF={item.file} />
                    ))
                      :
                      <ViewPDFBtn PDF={false} />
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default EventsCalendarList