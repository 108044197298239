import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { fetchBreedingServicing, fetchBreedingBirths } from '../../_actions/page-breeding.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import EventsCalendar from '../../_components/EventsCalendar'
import BreedingTable from '../../_components/BreedingTable'
import LoadingSplash from '../../_components/LoadingSplash'

class BreedingPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (
      this.props.pageBreedingData.servicing.needsInit ||
      this.props.pageBreedingData.births.needsInit ||
      this.props.pageBreedingData.servicing.error ||
      this.props.pageBreedingData.births.error
    ) {
      await Promise.all([this.props.dispatch(fetchBreedingServicing()), this.props.dispatch(fetchBreedingBirths())])
    }

    if (
      this.props.pageBreedingData.servicing.error ||
      this.props.pageBreedingData.births.error
    ) {
      if (
        this.props.pageBreedingData.servicing.error === 401 ||
        this.props.pageBreedingData.births.error === 401  
      ) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Chiefswood Stables | Breeding</title>
        </Helmet>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Breeding Calendar'} />
            <EventsCalendar
              eventsData={this.props.pageBreedingData.servicing}
              title={'Horse Servicing'}
            />
            <BreedingTable tableData={this.props.pageBreedingData.births} />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageBreedingData } = state
  return {
    pageBreedingData
  }
}

export default connect(mapStateToProps)(BreedingPage)