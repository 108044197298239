import React, { Component } from 'react'
import BigCalendar from 'react-big-calendar'
import EventsCalendarList from './EventsCalendarList'
import CalendarFilterBar from './CalendarFilterBar'
import moment from 'moment'

const localizer = BigCalendar.momentLocalizer(moment)
moment.updateLocale('en', { weekdaysMin: 'S_M_T_W_T_F_S'.split('_') })

const formats = {
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, 'dd')
}

const customEventWrapper = (event) => {
  const isPotential = /potential/i.test(event.event.additionalInfoData[0]) ? ' is-potential' : '';
  const isSelected = event.selected ? ' rbc-selected' : '';

  const handleEventClick = () => {
    event.onSelect(event.event);
  };

  return (
    <button className={`rbc-event${isPotential}${isSelected}`} onClick={handleEventClick}>
      <div className="rbc-event-content" title={event.event.title}>
        <div>{event.event.title}</div>
      </div>
    </button>
  )
}

class EventsCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      events: [],
      selectedDate: null,
      selectedEvent: null,
    }

    this.selectedEventId = null
  }

  componentDidMount() {
    this.initEvents()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventsData !== this.props.eventsData && this.props.eventsData.length) {
      this.initEvents();
    }
  }

  initEvents() {
    const eventsByDate = this.props.eventsData.map((eventItem, idx) => {
      return Object.assign({}, eventItem, {
        reactID: idx,
        start: moment(`${eventItem.start} -0500`, 'YYYY-MM-DD ZZ'),
        end: moment(`${eventItem.end} -0500`, 'YYYY-MM-DD ZZ'),
      })
    })

    this.setState({
      events: eventsByDate
    }, () => {
      this.selectMostCurrentEvent()
    })
  }

  selectMostCurrentEvent() {
    if (this.state.events.length === 0) {
      return;
    }

    const todaysDate = moment().startOf('day').valueOf()
    let closestEventIdx = null

    for (let i = 0, len = this.state.events.length; i < len; i++) {
      let eventDate = this.state.events[i].start.valueOf()

      if (eventDate >= todaysDate) {
        closestEventIdx = i
        break
      }
    }

    if (closestEventIdx === null) {
      closestEventIdx = this.state.events.length - 1
    }

    this.setSelectedEvent(closestEventIdx)
    this.setSelectedDate(closestEventIdx)
  }

  setSelectedEvent(eventId) {
    const event = this.state.events[eventId]
    this.setState({
      selectedEvent: event
    }, () => {
      this.setSelectedOutlineHeight()
    })
  }

  selectEventHandler(event) {
    this.setSelectedDate(event.reactID)
    this.selectedEventId = event.reactID
    this.setState({
      selectedEvent: event
    })
  }

  setSelectedDate(eventId) {
    const date = this.state.events[eventId].start.toDate()

    this.setState({
      selectedDate: date
    })
  }

  setSelectedOutlineHeight() {
    if (document.getElementById('selectOutlineStyle')) {
      document.getElementsByTagName('head')[0].removeChild(document.getElementById('selectOutlineStyle'))
    }
    const dateSelectOutlineHeight = document.querySelector('.rbc-row-content').clientHeight
    const newElem = document.createElement('style')
    newElem.setAttribute('id', 'selectOutlineStyle')
    newElem.innerHTML = `.rbc-date-cell.rbc-current:after{height:${dateSelectOutlineHeight}px}`
    document.getElementsByTagName('head')[0].appendChild(newElem)
  }

  handleNavigate(date, view, action) {
    const newMonth = moment(date).format('MM');
    const newYear = moment(date).format('YYYY');
    const currMonth = moment(this.state.selectedDate).format('MM');

    if (newMonth !== currMonth && this.props.fetchHomeEventsByMonth) {
      this.fetchEventsForMonth(newYear, newMonth);
    }

    this.setState({
      selectedDate: date
    }, () => {
      this.setSelectedOutlineHeight()
    })
  }

  fetchEventsForMonth(newYear, newMonth) {
    this.props.fetchHomeEventsByMonth(newYear, newMonth);
  }

  render() {
    return (
      <section>
        <div className="container-fluid events-clndr_main-cont">
          <div className="row flex-column flex-md-row">
            <div className="col col-md-6">
              <BigCalendar
                popup
                components={{eventWrapper: customEventWrapper}}
                formats={formats}
                date={this.state.selectedDate}
                localizer={localizer}
                events={this.state.events}
                selected={this.state.selectedEvent}
                views={['month']}
                filterTypes={this.state.filterTypes}
                activeFilters={this.state.activeFilters}
                filterbar={CalendarFilterBar}
                onSelectEvent={this.selectEventHandler.bind(this)}
                onNavigate={this.handleNavigate.bind(this)}
              />
            </div>
            <div className="col col-md-6 events-clndr_list-col-wrap">
              <h3 className="events-clndr_list-heading text-center">{this.props.title}</h3>
              <EventsCalendarList
                events={this.state.events}
                calendarSelectedEventId={this.selectedEventId}
                activeDate={this.state.selectedDate}
                setSelectedDate={this.setSelectedDate.bind(this)}
                setSelectedEvent={this.setSelectedEvent.bind(this)}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EventsCalendar