import React, { Component } from 'react'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { Default, Mobile } from '../../_components/_mediaqueries'
import { connect } from 'react-redux';
import moment from 'moment'

import { fetchHorseDetail } from '../../_actions/page-detail.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import RacingResultsTable from '../../_components/RacingResultsTable'
import ViewPDFBtn from '../../_components/EventsCalendar/ViewPDFBtn'
import LoadingSplash from '../../_components/LoadingSplash'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/css/swiper.css'

import iconHorzScroll from '../../_img/icon-horz-scroll-indic.svg'

import { parseQuery, handleViewNote } from '../../_components/_utils'

import iconDownArrow from '../../_img/icon-arrow-down.svg'

const swiperParams = {
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
  spaceBetween: 45,
  slidesPerView: 2,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    767: {
      spaceBetween: 0,
      slidesPerView: 1,
    },
  },
}

class HorseDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isMobile: window.matchMedia('(max-width: 767px)').matches,
      pdfPw: '',
      pdfAuthError: ''
    }

    this.pdfAuthInput = React.createRef();

    this.carouselInitFlag = false
    this.carouselTimeout = null
    this.checkIfMobile = this.checkIfMobile.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
    this.detailedReportInputChange = this.detailedReportInputChange.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
    document.removeEventListener('keypress', this.handleKeypress)
  }

  checkIfMobile() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 767px)').matches,
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({
      currentHorseID: parseQuery(this.props.location.search).id
    }, async () => {
      await this.fetchHorseDetail()

      document.addEventListener('keypress', this.handleKeypress);

      if (localStorage.getItem('detailed_report_pw') && this.pdfAuthInput.current) {
        this.setState({ pdfPw: localStorage.getItem('detailed_report_pw') })
        this.pdfAuthInput.current.classList.add('is-auth')
        this.pdfAuthInput.current.value = localStorage.getItem('detailed_report_pw')
      }
    })

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentHorseID != parseQuery(this.props.location.search).id) {
      this.setState({
        currentHorseID: parseQuery(this.props.location.search).id,
        isLoading: true
      }, () => {
        this.fetchHorseDetail()
      })
    }
  }

  loadNewHorse(id) {
    this.props.history.push(`/horses/detail?id=${id}`)
  }

  async fetchHorseDetail() {
    await this.props.dispatch(fetchHorseDetail(this.state.currentHorseID))

    window.scrollTo(0, 0)

    if (this.props.pageDetailData.details.error) {
      if (this.props.pageDetailData.details.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}${window.location.search}`)
      }
    } else {
      window.addEventListener('resize', this.checkIfMobile)
      this.resizeWrap = document.querySelector('.id-nums_resize-wrap')
      this.setState({
        isLoading: false
      })

      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}${window.location.search}`)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.carouselTimeout)
  }

  handleKeypress(e) {
    if (e.which === 13) {
      if (document.activeElement === this.pdfAuthInput.current) {
        this.authDetailedReport();
      }
    }
  }

  carouselMap(item, idx, arr) {
    return (
      <div key={idx}>
        <img src={item.value.horseImage.url} />
        <div className="carousel_caption">{item.value.imageCaption}</div>
      </div>
    )
  }

  renderFamilyTree() {
    const familyTree = this.props.pageDetailData.details.getFamilyTree
    let colMarkup = `<div class="col d-flex flex-column">{names}</div>`
    let nameMarkup =
      `<div class="col d-flex flex-column justify-content-center">
        {name}
      </div>`
    let familyTreeMarkup = [
      `<div class="col d-flex flex-column">
        <div class="col d-flex flex-column justify-content-center">
          ${familyTree.name}
        </div>
      </div>`
    ]
    if (familyTree.sire.name || familyTree.dam.name) {
      let names = [
        nameMarkup.replace('{name}', familyTree.sire.name),
        nameMarkup.replace('{name}', familyTree.dam.name)
      ]
      familyTreeMarkup.push(colMarkup.replace('{names}', names.join('')))
    }

    let lastSireBranch = familyTree.sire
    let sireBranch = familyTree.sire
    let sireNeedsBacktrack = false
    let lastDamBranch = familyTree.dam
    let damBranch = familyTree.dam
    let damNeedsBacktrack = false

    const eachRecursiveSire = (obj, names = []) => {
      names.push(nameMarkup.replace('{name}', obj.sire !== undefined ? obj.sire.name : obj.name))
      names.push(nameMarkup.replace('{name}', obj.dam !== undefined ? obj.dam.name : obj.name))

      if (sireNeedsBacktrack) {
        sireNeedsBacktrack = false
        eachRecursiveSire(lastSireBranch.dam, names)
      }

      eachRecursiveDam(damBranch, names)
    }

    const eachRecursiveDam = (obj, names) => {
      names.push(nameMarkup.replace('{name}', obj.sire !== undefined ? obj.sire.name : obj.name))
      names.push(nameMarkup.replace('{name}', obj.dam !== undefined ? obj.dam.name : obj.name))

      if (damNeedsBacktrack) {
        damNeedsBacktrack = false
        eachRecursiveDam(lastDamBranch.dam, names)
      }

      if (sireBranch.sire) {
        lastSireBranch = sireBranch
        lastDamBranch = damBranch
        sireBranch = sireBranch.sire
        damBranch = damBranch.sire
        sireNeedsBacktrack = true
        damNeedsBacktrack = true
        familyTreeMarkup.push(colMarkup.replace('{names}', names.join('')))
        eachRecursiveSire(sireBranch)
      }
    }

    if (sireBranch.sire && sireBranch.dam) {
      eachRecursiveSire(sireBranch)
    }

    return familyTreeMarkup.join('')
  }

  statusBoxMap(item, idx, arr) {
    return (
      <React.Fragment key={idx}>
        <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
          <div className="status-box_heading">{item}</div>
          <div className="status-box_cell">
            {this.state.details.statusBox.tableCells[idx]}
          </div>
        </div>
        {this.state.isMobile && idx === arr.length - 1 && (
          <div onClick={handleViewNote} className="col-12 table_view-note-btn">
            <span>View Note</span>
            <img src={iconDownArrow} />
          </div>
        )}
      </React.Fragment>
    )
  }

  racingTableHeadingMap(item, idx) {
    if (this.state.isMobile) {
      return null
    }

    return (
      <React.Fragment key={idx}>
        <div className="col">{item}</div>
      </React.Fragment>
    )
  }

  racingTableEntitiesMap(item, idx) {
    return (
      <div key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.state.isMobile && (
                    <div>
                      {this.props.pageDetailData.details.racingStats.tableHeadings[idx]}
                    </div>
                  )}
                  <div>
                    {idx === arr.length - 1 && (
                      <span className="dollar-sign-margin">$</span>
                    )}
                    {entityItem}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
      </div>
    )
  }

  produceTableHeadingMap(item, idx, arr) {
    if (this.state.isMobile) {
      return null
    }

    return (
      <React.Fragment key={idx}>
        <div className="col">{item}</div>
        {idx === arr.length - 1 && <div className="col" />}
      </React.Fragment>
    )
  }

  produceTableEntitiesMap(item, idx) {
    return (
      <div onClick={() => { this.loadNewHorse(item.detailID) }} key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.state.isMobile && (
                    <div>
                      {this.props.pageDetailData.details.produceRecord.tableHeadings[idx]}
                    </div>
                  )}
                  <div>
                    {(idx === 5 || idx === 6) && (
                      <span className="dollar-sign-margin">$</span>
                    )}
                    {entityItem}
                  </div>
                </div>
                {idx === arr.length - 1 && (
                  <div
                    onClick={handleViewNote}
                    className="col table_view-note-btn"
                  >
                    <span>View Note</span>
                    <img src={iconDownArrow} />
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
        <div className="table_item-note">
          <div className="d-flex">
            <div className="spacer-15px" />
            <div>
              <div className="table_note-label">Notes</div>
              <div
                className="table_note-body"
                dangerouslySetInnerHTML={{ __html: item.note }}
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    )
  }

  detailedReportInputChange(e) {
    if (this.pdfAuthInput.current.classList.contains('is-auth')) {
      this.pdfAuthInput.current.classList.remove('is-auth')
    }
    this.setState({ pdfPw: e.target.value, pdfAuthError: '' })
  }

  async authDetailedReport() {
    if (this.state.pdfPw === '') {
      this.setState({ pdfAuthError: 'Please enter the password to download this file.' })
      this.pdfAuthInput.current.focus()
      return;
    }
    const res = await fetch('/api/v2/pdf-auth/', { 
      method: 'POST',
      headers: { 
        'Content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
      },
      body: JSON.stringify({ pw: this.state.pdfPw }) 
    })
    const resJson = await res.json()

    if (res.status === 200) {
      localStorage.setItem('detailed_report_pw', this.state.pdfPw)
      this.pdfAuthInput.current.classList.add('is-auth')
      this.setState({ pdfAuthError: '' })

      const file = this.props.pageDetailData.details.detailsReport.meta.download_url
      this.downloadURI(file, file.split('/').pop())
    } else if (res.status === 401) {
      this.setState({ pdfAuthError: resJson.message })
    }

  }

  downloadURI(uri, name) {
    var link = document.createElement("a")
    link.style.display = 'none'
    link.download = name
    link.target = "_blank"
    link.href = uri
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading || this.props.pageDetailData.details.needsInit ? (
          <LoadingSplash />
        ) : (
            <React.Fragment>
              <PageHeader
                heading={this.props.pageDetailData.details.name}
                color={this.props.pageDetailData.details.colorName}
                gender={this.props.pageDetailData.details.genderName}
                foaled={moment(this.props.pageDetailData.details.birthDate).format('MMM D[,] YYYY')}
              />
              {(this.props.pageDetailData.details.image_section && this.props.pageDetailData.details.image_section.length > 0) &&
                <section className="carousel">
                  <div className="container-fluid">
                    <Swiper
                      {...swiperParams}
                      ref={node =>
                        (this.swiper = node !== null ? node.swiper : null)
                      }
                    >
                      {this.props.pageDetailData.details.image_section.map(
                        this.carouselMap.bind(this)
                      )}
                    </Swiper>
                  </div>
                </section>
              }
              <section className="id-nums">
                <div className="container-fluid">
                  <div className="d-flex justify-content-center">
                    <div className="d-inline-flex justify-content-center id-nums_resize-wrap">
                      <div className="id-nums_resize-inner">
                        <div
                          className="d-flex flex-column flex-md-row id-nums_num"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="col">Horse Identification No.</span> <span class="col">${
                              this.props.pageDetailData.details.idNum
                              }</span>`,
                          }}
                        />
                        <div
                          className="d-flex flex-column flex-md-row id-nums_num"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="col">Horse Registration No.</span> <span class="col">${
                              this.props.pageDetailData.details.registerNum
                              }</span>`,
                          }}
                        />
                        <div
                          className="d-flex flex-column flex-md-row id-nums_num"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="col">Horse Microchip No.</span> <span class="col">${
                              this.props.pageDetailData.details.microchipNum
                              }</span>`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="family-tree">
                <div className="container-fluid">
                  <h2 className="section-heading">Family Tree</h2>
                  <div className="family-tree_scroll-wrap">
                    <div
                      className="row family-tree_wrap"
                      dangerouslySetInnerHTML={{
                        __html: this.renderFamilyTree(),
                      }}
                    />
                  </div>
                  <Mobile>
                    <div className="row flex-column justify-content-center align-items-center family-tree_scroll-indic">
                      <img src={iconHorzScroll} />
                      <div>Scroll to view</div>
                    </div>
                  </Mobile>
                </div>
              </section>
              <section className="status-box">
                <div className="container-fluid">
                  <h2 className="section-heading">Status Box</h2>
                  <div className="status-box_wrap">
                    <div className="row pad-x-15px">

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">Status</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.statusName ? this.props.pageDetailData.details.statusName : '-'}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">Location</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.locationName ? this.props.pageDetailData.details.locationName : '-'}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">In Care of</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.trainerName ? this.props.pageDetailData.details.trainerName : '-'}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">Last Serviced</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.getServiceHistory.items.length
                            ? moment(this.props.pageDetailData.details.getServiceHistory.items[0].lastServicedDate).format('MMM D[,] YYYY')
                            : '-'
                          }
                        </div>
                      </div>

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">Sire</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.sireName ? this.props.pageDetailData.details.sireName : '-'}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 d-inline-flex flex-column flex-md-column status-box_item">
                        <div className="status-box_heading">Est. Foal Date</div>
                        <div className="status-box_cell">
                          {this.props.pageDetailData.details.getServiceHistory.items.length
                            ? moment(this.props.pageDetailData.details.getServiceHistory.items[0].estimatedDate).format('MMM D[,] YYYY')
                            : '-'
                          }
                        </div>
                      </div>

                      {this.state.isMobile &&
                        <div onClick={handleViewNote} className="col-12 table_view-note-btn">
                          <span>View Note</span>
                          <img src={iconDownArrow} />
                        </div>
                      }
                    </div>
                    <hr />
                    <div className="pad-x-15px table_item-note">
                      <div className="status-box_heading">Notes</div>
                      <div className="status-box_cell max-w-1100px" dangerouslySetInnerHTML={{ __html: this.props.pageDetailData.details.notes }}>
                      </div>
                      <Mobile>
                        <hr />
                      </Mobile>
                    </div>
                  </div>
                </div>
              </section>
              {this.props.pageDetailData.details.racingStats.tableEntities.length > 0 &&
                <section className="racing-stats">
                  <div className="container-fluid">
                    <h2 className="section-heading">Racing Stats</h2>
                    <div className="table">
                      <div className="row table_heading-row">
                        {this.props.pageDetailData.details.racingStats.tableHeadings.map(
                          this.racingTableHeadingMap.bind(this)
                        )}
                      </div>
                      {this.props.pageDetailData.details.racingStats.tableEntities.map(
                        this.racingTableEntitiesMap.bind(this)
                      )}
                    </div>
                  </div>
                </section>
              }
              {this.props.pageDetailData.details.produceRecord.tableEntities.length > 0 &&
                <section className="produce-record">
                  <div className="container-fluid">
                    <h2 className="section-heading">Produce Record</h2>
                    <div className="table">
                      <div className="row table_heading-row">
                        {this.props.pageDetailData.details.produceRecord.tableHeadings.map(
                          this.produceTableHeadingMap.bind(this)
                        )}
                      </div>
                      {this.props.pageDetailData.details.produceRecord.tableEntities.map(
                        this.produceTableEntitiesMap.bind(this)
                      )}
                    </div>
                  </div>
                </section>
              }
              {this.props.pageDetailData.details.racingResults.tableEntities.length > 0 &&
                <RacingResultsTable
                  tableData={
                    this.props.pageDetailData.details.racingResults.tableEntities
                  }
                  noLink
                  title={'Racing Results'}
                  isMobile={this.state.isMobile}
                />
              }
              {((this.props.pageDetailData.details.genealogy_file && this.props.pageDetailData.details.genealogy_file.meta.download_url) &&
                (this.props.pageDetailData.details.genealogy_notes != '<p></p>' || this.props.pageDetailData.details.genealogy_notes != '')) &&
                <section className="genealogy">
                  <div className="container-fluid">
                    <h2 className="section-heading">Genealogy</h2>
                    <div className="row no-gutters justify-content-center genealogy_wrap">
                      <div className="col-12 col-md genealogy_body-copy"
                        dangerouslySetInnerHTML={{ __html: this.props.pageDetailData.details.genealogy_notes }}>
                      </div>
                      <div className="col-auto d-inline-flex justify-content-center">
                        <ViewPDFBtn
                          PDF={this.props.pageDetailData.details.genealogyDoc !== null && this.props.pageDetailData.details.genealogyDoc}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              }
              {(this.props.pageDetailData.details.detailsReport && this.props.pageDetailData.details.detailsReport.meta.download_url) &&
                <section className="detailed-report">
                  <div className="container-fluid">
                    <h2 className="section-heading">Detailed Report</h2>
                    <div className="row no-gutters justify-content-start genealogy_wrap">
                      <div className="col-auto d-inline-flex justify-content-center">
                        <ViewPDFBtn
                          authFunction={this.authDetailedReport.bind(this)}
                        />
                        <div className="detailed-report_input-wrap">
                          <p>A password is required to download this file.</p>
                          <input ref={this.pdfAuthInput} className="detailed-report_pw-input" type="password" value={this.state.PdfPw} onChange={this.detailedReportInputChange} />
                          {this.state.pdfAuthError === '' 
                            ? <p className="detailed-report_error">&nbsp;</p> 
                            : <p className="detailed-report_error">{this.state.pdfAuthError}</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </React.Fragment>
          )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageDetailData } = state
  return {
    pageDetailData
  }
}

export default connect(mapStateToProps)(withRouter(HorseDetailPage))