import React from 'react'
import anime from 'animejs'

export const handleViewNote = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const rowElem = e.currentTarget.parentNode.parentNode
  const noteElem = e.currentTarget.parentNode.parentNode.querySelector('.table_item-note')
  const btnLabelElem = e.currentTarget.querySelector('span')
  if (noteElem.classList.contains('is-active')) {
    anime({
      targets: noteElem,
      height: 0,
      paddingTop: 0,
      duration: 350,
      easing: 'easeInOutQuart'
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [1, 0],
      duration: 300,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'View Note'
    noteElem.classList.remove('is-active')
    rowElem.classList.remove('is-active')
  } else {
    anime({
      targets: noteElem,
      height: noteElem.scrollHeight + 31,
      paddingTop: '30px',
      duration: 350,
      easing: 'easeInOutQuart',
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [0, 1],
      delay: 300,
      duration: 350,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'Close Note'
    noteElem.classList.add('is-active')
    rowElem.classList.add('is-active')
  }
}

export const handleViewDetails = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const rowElem = e.currentTarget.parentNode.parentNode
  const noteElem = e.currentTarget.parentNode.parentNode.querySelector('.table_item-note')
  const btnLabelElem = e.currentTarget.querySelector('span')
  if (noteElem.classList.contains('is-active')) {
    anime({
      targets: noteElem,
      height: 0,
      paddingTop: 0,
      duration: 350,
      easing: 'easeInOutQuart'
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [1, 0],
      duration: 300,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'More Details'
    noteElem.classList.remove('is-active')
    rowElem.classList.remove('is-active')
  } else {
    anime({
      targets: noteElem,
      height: noteElem.scrollHeight + 31,
      paddingTop: '30px',
      duration: 350,
      easing: 'easeInOutQuart',
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [0, 1],
      delay: 300,
      duration: 350,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'Hide Details'
    noteElem.classList.add('is-active')
    rowElem.classList.add('is-active')
  }
}

export const handleViewDetailsUpcoming = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const rowElem = e.currentTarget.parentNode.parentNode.parentNode.parentNode
  const noteElem = e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelector('.table_item-note')
  const btnLabelElem = e.currentTarget.querySelector('span')
  const btnIcon = e.currentTarget.querySelector('img')
  if (noteElem.classList.contains('is-active')) {
    anime({
      targets: noteElem,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      duration: 350,
      easing: 'easeInOutQuart'
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [1, 0],
      duration: 300,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'More Details'
    btnIcon.style.transform = 'rotate(0deg)'
    noteElem.classList.remove('is-active')
    rowElem.classList.remove('is-active')
  } else {
    anime({
      targets: noteElem,
      height: noteElem.scrollHeight + 61,
      paddingTop: '30px',
      paddingBottom: '30px',
      duration: 350,
      easing: 'easeInOutQuart',
    })
    anime({
      targets: noteElem.querySelectorAll('div'),
      opacity: [0, 1],
      delay: 300,
      duration: 350,
      easing: 'easeOutQuart'
    })
    btnLabelElem.innerHTML = 'Hide Details'
    btnIcon.style.transform = 'rotate(180deg)'
    noteElem.classList.add('is-active')
    rowElem.classList.add('is-active')
  }
}

export const sortNamesAlphabetically = (nameOrderAsc, tableEntities) => {
  let newEntitesOrder = []
  if (nameOrderAsc) {
    newEntitesOrder = tableEntities.sort(function (a, b) {
      const Aname = a.tableCells[0].toLowerCase(), Bname = b.tableCells[0].toLowerCase()
      if (Aname < Bname) { return -1; }
      if (Aname > Bname) { return 1; }
      return 0;
    })
  } else {
    newEntitesOrder = tableEntities.sort(function (a, b) {
      const Aname = a.tableCells[0].toLowerCase(), Bname = b.tableCells[0].toLowerCase()
      if (Aname > Bname) { return -1; }
      if (Aname < Bname) { return 1; }
      return 0;
    })
  }
  return newEntitesOrder
}

export const FirstChild = (props) => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
}

export const parseQuery = (queryString) => {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pairIdx = pairs[i].indexOf('=');
    var pair = [pairs[i].substr(0, pairIdx), pairs[i].substr(pairIdx + 1)];
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
}

export const removeTime = (date) => date.toString().split(' ').slice(0, 4).join(' ')