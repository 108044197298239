import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

//const loggerMiddleware = createLogger();

export const history = createBrowserHistory()

export const store = createStore(
  rootReducer(history),
  applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware,
    apiMiddleware
  )
);