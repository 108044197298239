import {
  pageHorsesAllConsts,
} from '../_actions/page-horses.actions';

const initialState = {
  all: { needsInit: true },
}

export function pageHorses(state = initialState, action) {
  switch (action.type) {
    case pageHorsesAllConsts.REQUEST:
      return {
        ...state,
        all: { loading: true }
      }
    case pageHorsesAllConsts.SUCCESS:
      return {
        ...state,
        all: action.payload
      }
    case pageHorsesAllConsts.FAILURE:
      return {
        ...state,
        all: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}        