import {
  pageLoginMsgConsts,
} from '../_actions/page-login.actions';

const initialState = {
  msg: { needsInit: true },
  sessionExpired: false,
  loggedOut: false
}

export function pageLogin(state = initialState, action) {
  switch (action.type) {
    case pageLoginMsgConsts.REQUEST:
      return {
        ...state,
        msg: { loading: true },
        sessionExpired: false,
        loggedOut: false
      }
    case pageLoginMsgConsts.SUCCESS:
      return {
        ...state,
        msg: action.payload,
        sessionExpired: false,
        loggedOut: false
      }
    case pageLoginMsgConsts.FAILURE:
      return {
        ...state,
        msg: { error: action.error },
        sessionExpired: false,
        loggedOut: false
      }
    case 'USER_LOGOUT':
      return {
        ...state,
        sessionExpired: true,
        loggedOut: false
      }
    case 'USER_LOGOUT_NO_MSG':
      return {
        ...state,
        sessionExpired: false,
        loggedOut: true
      } 
    default:
      return state
  }
}  