import { RSAA } from 'redux-api-middleware'
import moment from 'moment'

export const pageLicenseConsts = {
  REQUEST: '@@PAGE_LICENSE/PAGE_LICENSE_REQUEST',
  SUCCESS: '@@PAGE_LICENSE/PAGE_LICENSE_SUCCESS',
  FAILURE: '@@PAGE_LICENSE/PAGE_LICENSE_FAILURE',
};

export const fetchLicenses = () => ({
  [RSAA]: {
    endpoint: `/api/v2/racinglicense/all`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLicenseConsts.REQUEST,
      {
        type: pageLicenseConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          let tableData = {
            tableHeadings: ['License', 'Owner', 'Expiry'],
            tableEntities: []
          }
          const fourWeeks = 604800000 * 4
          payload.items.forEach((item) => {
            let entity = {
              tableCells: [
                item.license,
                item.owner,
                moment(item.expiry).format('MMM D[,] YYYY')
              ],
              isExpiringSoon: moment(item.expiry, 'YYYY-MM-DD').valueOf() - moment().valueOf() < fourWeeks ? true : false,
              isExpired: moment().valueOf() > moment(item.expiry, 'YYYY-MM-DD').valueOf()
            }
            tableData.tableEntities.push(entity)
          })

          return tableData
        }
      },
      pageLicenseConsts.FAILURE
    ]
  }
})
