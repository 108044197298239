import { pageNewsfeedConsts } from '../_actions/page-newsfeed.actions';

const initialState = {
  newsfeed: { needsInit: true }
}

export function pageNewsfeed(state = initialState, action) {
  switch (action.type) {
    case pageNewsfeedConsts.REQUEST:
      return {
        ...state,
        newsfeed: { loading: true }
      }
    case pageNewsfeedConsts.SUCCESS:
      return {
        ...state,
        newsfeed: action.payload.items
      }
    case pageNewsfeedConsts.FAILURE:
      return {
        ...state,
        newsfeed: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState  
    default:
      return state
  }
}