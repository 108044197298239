import { pageHomeConsts } from '../_actions/page-home.actions';

const initialState = {
  events: { needsInit: true }
}

export function pageHome(state = initialState, action) {
  switch (action.type) {
    case pageHomeConsts.REQUEST:
      return {
        ...state,
        events: { loading: true }
      }
    case pageHomeConsts.SUCCESS:
      return {
        ...state,
        events: action.payload
      }
    case pageHomeConsts.FAILURE:
      return {
        ...state,
        events: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}