import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { handleViewDetails } from '../_utils'
import iconDownArrow from '../../_img/icon-arrow-down.svg'

import TableTabs from './TableTabs'

class RacingResultsTable extends Component {

  closeAllNotes() {
    [...document.querySelectorAll('.racing-results_item.is-active')]
      .forEach(item => {
        item.removeAttribute('style')
        item.classList.remove('is-active')
        item.querySelector('.table_view-note-btn span').innerHTML = 'View Note'
        for (let i = 0; i < item.children.length; i++) {
          item.children[i].removeAttribute('style')
          item.children[i].classList.remove('is-active')
        }
      })
  }

  setCurrentTab(currentTab) {
    this.closeAllNotes()
    this.props.setCurrentTab(currentTab)
  }

  viewPDFClick(e) {
    e.stopPropagation();
  }

  racingResultsMap(item, idx, arr) {
    let markup = []
    let mainRowMarkup = []
    let detailsRowMarkup = []

    item.main.tableHeadings.forEach((mainHeading, mainIdx, mainArr) => {
      let mainColMarkup =
        <React.Fragment>
          {(mainIdx === 0 || mainIdx % 4 === 0) && <div className="spacer-15px"></div>}

          {(!this.props.isMobile && mainIdx) === 4 &&
            <div onClick={handleViewDetails} className="col align-self-end table_view-note-btn">
              <span>More Details</span>
              <img src={iconDownArrow} />
            </div>
          }

          <div className="col d-flex justify-content-between d-md-block">
            <div className={`status-box_heading${mainIdx === 0 ? ' is-main-name' : ''}`}>{mainHeading}</div>
            <div className={`status-box_cell${mainIdx === 0 ? ' is-main-location' : ''}`}>{item.main.tableCells[mainIdx]}</div>
          </div>

          {(this.props.isMobile && mainIdx) === 6 &&
            <div onClick={handleViewDetails} className="col align-self-end table_view-note-btn">
              <span>More Details</span>
              <img src={iconDownArrow} />
            </div>
          }

          {mainIdx === 3 && <div className="w-100"></div>}

          {mainIdx === mainArr.length - 1 && <hr />}
        </React.Fragment>
      mainRowMarkup.push(mainColMarkup)
      if (mainIdx === mainArr.length - 1) {
        markup.push(React.createElement('div', { className: 'row no-gutters flex-column flex-md-row' }, mainRowMarkup))
      }
    })

    item.details.tableHeadings.forEach((detailsHeading, detailsIdx, detailsArr) => {
      let detailsColMarkup =
        <React.Fragment>
          {detailsIdx === 0 && <div className="spacer-15px"></div>}

          <div className={`col${(detailsIdx === 0 || detailsIdx === 3 && !this.props.isMobile) ? '-6' : ''} d-flex justify-content-between d-md-block`}>
            <div className={`status-box_heading${detailsIdx === 0 ? ' table_note-label' : ''}`}>{detailsHeading}</div>
            <div className={`status-box_cell${detailsIdx === 0 ? ' table_note-body' : ''}`}>
              {detailsIdx === detailsArr.length - 1 && <span className="dollar-sign-margin">$</span>}
              {detailsIdx === 3 
                ? <a className="text-link" onClick={this.viewPDFClick} href={item.details.tableCells[detailsIdx]}>View PDF         
                    <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g transform="translate(-891.000000, -454.000000)" className="events-clndr_view-pdf-fill">
                        <g transform="translate(522.000000, 287.000000)">
                          <g transform="translate(330.000000, 122.000000)">
                            <g transform="translate(0.000000, 23.000000)">
                              <path d="M61,23.625 C61,22.727194 60.2967794,22 59.4285714,22 L40.5714286,22 C39.7032206,22 39,22.727194 39,23.625 L39,46.375 C39,47.272806 39.7032206,48 40.5714286,48 L59.4285714,48 C60.2967794,48 61,47.272806 61,46.375 L61,23.625 Z M43.7142857,26.875 L50,26.875 L50,28.5 L43.7142857,28.5 L43.7142857,26.875 Z M56.2857143,43.125 L43.7142857,43.125 L43.7142857,41.5 L56.2857143,41.5 L56.2857143,43.125 Z M56.2857143,38.25 L43.7142857,38.25 L43.7142857,36.625 L56.2857143,36.625 L56.2857143,38.25 Z M56.2857143,33.375 L43.7142857,33.375 L43.7142857,31.75 L56.2857143,31.75 L56.2857143,33.375 Z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                : <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: item.details.tableCells[detailsIdx] }}></div>
              }  
            </div>
          </div>

          {(!this.props.isMobile && detailsIdx === 2) &&
            <React.Fragment>
              <div className="w-100"></div><div className="spacer-15px"></div>{/* <div className="col-6"></div> */}
            </React.Fragment>
          }

          {detailsIdx === detailsArr.length - 1 &&
            <hr className="last-hr" />
          }
        </React.Fragment>
      detailsRowMarkup.push(detailsColMarkup)
      if (detailsIdx === detailsArr.length - 1) {
        markup.push(React.createElement('div', { className: 'row no-gutters table_item-note racing-results_details-item' }, detailsRowMarkup))
      }
    })

    return React.createElement(this.props.noLink ? 'div' : Link, { key: idx, className: 'racing-results_item', to: `/horses/detail?id=${item.main.detailID}`, label: "Horse Details" }, markup)
  }

  render() {
    return (
      <section className="racing-results">
        {(this.props.currentTab && this.props.years) &&
          <TableTabs
            years={this.props.years}
            currentTab={this.props.currentTab}
            setCurrentTab={this.setCurrentTab.bind(this)}
            isMobile={this.props.isMobile}
          />
        }
        <div className="container-fluid">
          {this.props.title && <h2 className="section-heading">{this.props.title}</h2>}
          <div className="row no-gutters flex-column racing-results_wrap">
            {this.props.tableData && this.props.tableData.map(this.racingResultsMap.bind(this))}
          </div>
        </div>
      </section>
    )
  }
}

export default RacingResultsTable