import {
  pageDetailConsts,
} from '../_actions/page-detail.actions';

const initialState = {
  details: { needsInit: true }
}

export function pageDetail(state = initialState, action) {
  switch (action.type) {
    case pageDetailConsts.REQUEST:
      return {
        ...state,
        details: { loading: true }
      }
    case pageDetailConsts.SUCCESS:
      return {
        ...state,
        details: action.payload
      }
    case pageDetailConsts.FAILURE:
      return {
        ...state,
        details: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}  