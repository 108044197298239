import React from 'react'

export default function PageHeader(props) {
  return (
    <header className="page-header">
      <div className="container-fluid">
        <h1 className="page-header_heading">{props.heading}</h1>
        {(props.color || props.foaled || props.gender) ?
          <div className="page-header_horse-details">
            {props.color && <div className="page-header_detail is-color">{props.color}</div>}
            {props.gender && <div className="page-header_detail is-gender">{props.gender}</div>}
            {props.foaled && <div className="page-header_detail is-foaled">{props.foaled}</div>}
          </div>
          : null}
      </div>
    </header>
  )
}