import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import { fetchNewsfeed } from '../../_actions/page-newsfeed.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import NewsFeedList from '../../_components/NewsFeedList'
import LoadingSplash from '../../_components/LoadingSplash'

class NewsfeedPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newsfeedEntities: null,
      isLoading: true
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (
      this.props.pageNewsfeedData.newsfeed.needsInit ||
      this.props.pageNewsfeedData.newsfeed.error
      ) {
      await this.props.dispatch(fetchNewsfeed())
    }

    if (this.props.pageNewsfeedData.newsfeed.error) {
      if (this.props.pageNewsfeedData.newsfeed.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })

      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Newsfeed'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Newsfeed'} />
            <NewsFeedList data={this.props.pageNewsfeedData.newsfeed.length > 0 && this.props.pageNewsfeedData.newsfeed} />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageNewsfeedData } = state
  return {
    pageNewsfeedData
  }
}

export default connect(mapStateToProps)(NewsfeedPage)