import React, { Component } from 'react'

class CalendarFilterBar extends Component {

  handleFilterSelect(e) {
    let { activeFilters } = this.props;
    let filterToUpdate = {}
    filterToUpdate.operation = e.target.checked ? 'add' : 'remove'
    filterToUpdate.filterType = e.target.getAttribute('id')

    if(filterToUpdate.filterType === 'all' && filterToUpdate.operation === 'remove') {
      activeFilters = new Set()
      this.setAllCheckboxes('unchecked')
    } else if (filterToUpdate.filterType !== 'all' && filterToUpdate.operation === 'remove') {
      document.getElementById('all').checked = false
    } 

    if (filterToUpdate.filterType === 'all' && filterToUpdate.operation === 'add') {
      activeFilters = new Set([...this.props.filterTypes])
      this.setAllCheckboxes('checked')
    } else if (filterToUpdate.operation === 'remove') {
      activeFilters.delete(filterToUpdate.filterType)
    } else {
      activeFilters.add(filterToUpdate.filterType)
    }

    if(activeFilters.size === this.props.filterTypes.length) {
      document.getElementById('all').checked = true
    }

    this.props.filterByTypeFunc(activeFilters)
  }

  setAllCheckboxes(state) {
    [].slice.call(document.querySelectorAll('.events-clndr_filter-bar-input-wrap input'))
        .forEach((input) => { input.checked = state === 'checked' ? true : false })
  }

  filterTypesMap(item, idx) {
    return (
      <li key={idx + 1} className={`events-clndr_filter-bar-input-wrap is-${item}-filter`}>
        <input type="checkbox" id={item} name="eventType" value={item} defaultChecked />
        <label htmlFor={item}>{item}</label>
      </li>
    )
  }

  render() {
    return (
      <div style={{ height: '90px', width: '100%', background: 'lightgrey' }}>
        <ul className="events-clndr_filter-bar" onChange={this.handleFilterSelect.bind(this)}>
          <li key={0} className="events-clndr_filter-bar-input-wrap is-all-filter">
            <input type="checkbox" id="all" name="eventType" value="all" defaultChecked />
            <label htmlFor="all">All</label>
          </li>
          { this.props.filterTypes.map(this.filterTypesMap.bind(this)) }
        </ul>
      </div>
    )
  }
}

export default CalendarFilterBar