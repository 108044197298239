import { RSAA } from 'redux-api-middleware'

export const pageHomeConsts = {
  REQUEST: '@@PAGE_HOME/PAGE_HOME_REQUEST',
  SUCCESS: '@@PAGE_HOME/PAGE_HOME_SUCCESS',
  FAILURE: '@@PAGE_HOME/PAGE_HOME_FAILURE',
};

export const fetchHomeEventsByMonth = (year, month) => ({
  [RSAA]: {
    endpoint: `/api/v2/raceByMonth/${year}/${month}/asc/`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageHomeConsts.REQUEST,
      {
        type: pageHomeConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          const additionalInfoLabels = ['Status', 'Racetrack', 'Condition', 'Distance', 'Jockey', 'Trainer', 'Post Time', 'Race #', 'Surface', 'Finish', 'Earnings']
          let eventsData = []
          payload.items.forEach(item => {
            let entity = {
              title: item.horse,
              detail: item.track,
              start: item.raceDate,
              end: item.raceDate,
              preRacePDF: item.preRaceDoc,
              postRacePDF: item.postRaceDoc,
              additionalInfoLabels,
              additionalInfoData: [
                item.status,
                item.track,
                item.condition,
                item.distance,
                item.jockey,
                item.trainer,
                item.postTime,
                item.raceNumber,
                item.surface,
                item.finishPlace,
                `$ ${item.earning}`
              ]
            }
            eventsData.push(entity)
          })
          return eventsData
        }
      },
      pageHomeConsts.FAILURE
    ]
  }
})