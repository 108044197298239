import { RSAA } from 'redux-api-middleware'

export const pageEventsConsts = {
  REQUEST: '@@PAGE_EVENTS/PAGE_EVENTS_REQUEST',
  SUCCESS: '@@PAGE_EVENTS/PAGE_EVENTS_SUCCESS',
  FAILURE: '@@PAGE_EVENTS/PAGE_EVENTS_FAILURE',
};

export const fetchEvents = () => ({
  [RSAA]: {
    endpoint: `/api/v2/events/all`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageEventsConsts.REQUEST,
      pageEventsConsts.SUCCESS,
      pageEventsConsts.FAILURE
    ]
  }
})
