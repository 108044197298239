import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import TableTabs from './TableTabs'

import { sortNamesAlphabetically } from '../_utils'

import iconSortArrow from '../../_img/icon-sort-arrow.svg'

class CurrentHorsesTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTableEntities: null,
      currentTab: null,
      nameOrderAsc: true
    }

    this.MASTER_TABLE_ENTITES = []
  }

  componentDidMount() {
    this.setState({
      currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableByTab(this.props.tableData.currentTab)),
      currentTab: this.props.tableData.currentTab
    })
    this.MASTER_TABLE_ENTITES = [...this.props.tableData.tableEntities]
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentTab !== null && this.state.currentTab !== prevState.currentTab && Object.keys(prevProps.tableData).length) {
      this.setState({
        currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableByTab())
      }, () => {
        this.props.setActiveTableData(this.state.currentTableEntities)
      })
    }
    if (this.props.activeSearchTerm !== null && this.props.activeSearchTerm !== prevProps.activeSearchTerm) {
      this.setState({
        currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableBySearchTerm())
      })
    }
  }

  filterTableByTab(currentTab = this.state.currentTab) {
    if (this.state.currentTab === 'all horses') {
      return [...this.MASTER_TABLE_ENTITES]
    }
    return this.MASTER_TABLE_ENTITES.filter((item) => {
      return item.state === currentTab
    })
  }

  resetTableFromTab() {
    this.setState({
      currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableByTab())
    })
  }

  filterTableBySearchTerm() {
    return this.state.currentTableEntities.filter((item) => {
      return item.tableCells[0] === this.props.activeSearchTerm
    })
  }

  handleNameSort() {
    this.setState({
      nameOrderAsc: !this.state.nameOrderAsc,
      currentTableEntities: sortNamesAlphabetically(!this.state.nameOrderAsc, this.state.currentTableEntities)
    })
  }

  setCurrentTab(currentTab) {
    this.setState({
      currentTab
    })
    this.props.signalTabChange()
  }

  tableHeadingMap(item, idx) {
    if (this.props.isMobile && idx > 0) {
      return null
    }

    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
          {idx === 0 &&
            <div onClick={this.handleNameSort.bind(this)}
              className={`table_sort-alphabetical-btn${this.state.nameOrderAsc ? ' is-asc' : ' is-desc'}`}>
              <img src={iconSortArrow} />
            </div>}
        </div>
      </React.Fragment>
    )
  }

  currentTableEntitiesMap(item, idx) {
    return (
      <Link to={`/horses/detail?id=${item.detailID}`} label="Horse Details" key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.props.isMobile && <div>
                    {this.props.tableData.tableHeadings[idx]}
                  </div>}
                  <div>
                    {idx === arr.length - 1 && <span className="dollar-sign-margin">$</span>}
                    {entityItem}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
      </Link>
    )
  }

  render() {
    return (
      <section className="breeding-table">
        <TableTabs
          horseStates={this.props.tableData.horseStates}
          currentTab={this.state.currentTab === null ? '' : this.state.currentTab}
          setCurrentTab={this.setCurrentTab.bind(this)}
          isMobile={this.props.isMobile}
        />
        <div className="table">
          <div className="container-fluid">
            <div className="row table_heading-row">
              {this.props.tableData.tableHeadings && this.props.tableData.tableHeadings.map(this.tableHeadingMap.bind(this))}
            </div>
            {this.state.currentTableEntities && this.state.currentTableEntities.map(this.currentTableEntitiesMap.bind(this))}
          </div>
        </div>
      </section>
    )
  }
}

export default CurrentHorsesTable