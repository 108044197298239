import React, { Component } from 'react'
import moment from 'moment'
import { Default, Mobile } from '../_mediaqueries'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/css/swiper.css'

import iconDownArrow from '../../_img/icon-arrow-down.svg'

const swiperParams = {
  spaceBetween: 15,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
}

class ResultsOverview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      CDNBreakdownOpen: false,
      USDBreakdownOpen: false,
      currentEarnings: null,
      currentStats: null
    }
    this.MASTER_EARNINGS = []
    this.MASTER_STATS = []
  }

  componentDidMount() {
    this.MASTER_EARNINGS = [...this.props.earningsData.items]
    this.MASTER_STATS = [...this.props.statsData.items]
    this.setCurrentYear()
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentTab !== prevProps.currentTab || !prevProps.currentTab) {
      this.setCurrentYear()
    }
  }

  setCurrentYear() {
    const currentEarnings = this.MASTER_EARNINGS.find(item => (item.year == this.props.currentTab))
    const currentStats = this.MASTER_STATS.find(item => (item.year == this.props.currentTab))
    this.setState({
      currentEarnings,
      currentStats
    })
  }

  toggleCDNBreakdown(e) {
    this.toggleBTNLabel(e, !this.state.CDNBreakdownOpen)
    this.setState({
      CDNBreakdownOpen: !this.state.CDNBreakdownOpen,
      USDBreakdownOpen: false
    })
  }

  toggleUSDBreakdown(e) {
    this.toggleBTNLabel(e, !this.state.USDBreakdownOpen)
    this.setState({
      USDBreakdownOpen: !this.state.USDBreakdownOpen,
      CDNBreakdownOpen: false
    })
  }

  toggleBTNLabel(e, state) {
    const label = e.currentTarget.querySelector('span')
    const icon = e.currentTarget.querySelector('img');
    [...document.querySelectorAll('.table_view-note-btn')]
      .forEach((btn) => {
        btn.querySelector('span').innerHTML = 'More Details'
        btn.querySelector('img').style.transform = 'rotate(0deg)'
      })
    if (state) {
      label.innerHTML = 'Less Details'
      icon.style.transform = 'rotate(180deg)'
    } else {
      label.innerHTML = 'More Details'
      icon.style.transform = 'rotate(0deg)'
    }
  }

  render() {
    return (
      <section className="results-overview">
        <div className="container-fluid">
          <Default>
            <div className="row results-overview_top">
              <div className="col results-overview_total-races d-inline-flex flex-column justify-content-center">
                <div className="results-overview_num is-main">
                  {this.state.currentStats !== null ? this.state.currentStats.raceCount : ' '}
                </div>
                <div className="results-overview_label is-main">
                  Total Races
                </div>
              </div>
              <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                <div className="results-overview_label">
                  Wins
                </div>
                <div className="results-overview_num">
                  {this.state.currentStats !== null ? this.state.currentStats.win : ' '}
                </div>
                <div className="results-overview_label">
                  {this.state.currentStats !== null ? `${this.state.currentStats.winInPercent}%` : ' '}
                </div>
              </div>
              <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                <div className="results-overview_label">
                  Place
                </div>
                <div className="results-overview_num">
                  {this.state.currentStats !== null ? this.state.currentStats.place : ' '}
                </div>
                <div className="results-overview_label">
                  {this.state.currentStats !== null ? `${this.state.currentStats.placeInPercent}%` : ' '}
                </div>
              </div>
              <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                <div className="results-overview_label">
                  Show
                </div>
                <div className="results-overview_num">
                  {this.state.currentStats !== null ? this.state.currentStats.show : ' '}
                </div>
                <div className="results-overview_label">
                  {this.state.currentStats !== null ? `${this.state.currentStats.showInPercent}%` : ' '}
                </div>
              </div>
              <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                <div className="results-overview_label">
                  ITM
                </div>
                <div className="results-overview_num">
                  {this.state.currentStats !== null ? this.state.currentStats.ITM : ' '}
                </div>
                <div className="results-overview_label">
                  {this.state.currentStats !== null ? `${this.state.currentStats.ITMinPercent}%` : ' '}
                </div>
              </div>
            </div>
          </Default>
          <Mobile>
            <Swiper {...swiperParams}>
              <div className="d-flex align-items-center">
                <div className="spacer-15px"></div>
                <div className="col results-overview_total-races d-inline-flex flex-column justify-content-center">
                  <div className="results-overview_num is-main">
                    {this.state.currentStats !== null ? this.state.currentStats.raceCount : ' '}
                  </div>
                  <div className="results-overview_label is-main">
                    Total Races
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="spacer-15px"></div>
                <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                  <div className="results-overview_label">
                    Wins
                  </div>
                  <div className="results-overview_num">
                    {this.state.currentStats !== null ? this.state.currentStats.win : ' '}
                  </div>
                  <div className="results-overview_label">
                    {this.state.currentStats !== null ? `${this.state.currentStats.winInPercent}%` : ' '}
                  </div>
                </div>
                <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                  <div className="results-overview_label">
                    Place
                  </div>
                  <div className="results-overview_num">
                    {this.state.currentStats !== null ? this.state.currentStats.place : ' '}
                  </div>
                  <div className="results-overview_label">
                    {this.state.currentStats !== null ? `${this.state.currentStats.placeInPercent}%` : ' '}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="spacer-15px"></div>
                <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                  <div className="results-overview_label">
                    Show
                  </div>
                  <div className="results-overview_num">
                    {this.state.currentStats !== null ? this.state.currentStats.show : ' '}
                  </div>
                  <div className="results-overview_label">
                    {this.state.currentStats !== null ? `${this.state.currentStats.showInPercent}%` : ' '}
                  </div>
                </div>
                <div className="col results-overview_top-item d-inline-flex flex-column justify-content-between">
                  <div className="results-overview_label">
                    ITM
                  </div>
                  <div className="results-overview_num">
                    {this.state.currentStats !== null ? this.state.currentStats.ITM : ' '}
                  </div>
                  <div className="results-overview_label">
                    {this.state.currentStats !== null ? `${this.state.currentStats.ITMinPercent}%` : ' '}
                  </div>
                </div>
              </div>
            </Swiper>
          </Mobile>
          <div className="row results-overview_bot">
            <div className="col-12 col-md d-inline-flex align-items-center results-overview_main-total">
              <div className="col results-overview_label is-white">
                Total Earnings
                </div>
              <div className="col results-overview_num is-white">
                {`$ ${this.state.currentEarnings !== null && this.state.currentEarnings.yearTtlEarning}`}
              </div>
            </div>
            <div className="col-6 col-md flex-column flex-md-row d-inline-flex align-items-center results-overview_bot-item"
              style={!this.state.CDNBreakdownOpen && this.state.USDBreakdownOpen ? { 'backgroundColor': 'transparent' } : {}}
            >
              <div className="col results-overview_label">
                {`${(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[0]) && this.state.currentEarnings.yearInDetails[0].localCurrency} Earnings`}
                <Default>
                  <div onClick={this.toggleCDNBreakdown.bind(this)} className="table_view-note-btn">
                    <span>More Details</span>
                    <img src={iconDownArrow} />
                  </div>
                </Default>
              </div>
              <div className="col results-overview_num">
                {`$ ${(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[0]) && this.state.currentEarnings.yearInDetails[0].localEarningTtl}`}
                <Mobile>
                  <div onClick={this.toggleCDNBreakdown.bind(this)} className="table_view-note-btn">
                    <span>More Details</span>
                    <img src={iconDownArrow} />
                  </div>
                </Mobile>
              </div>
            </div>
            <div className="col-6 col-md flex-column flex-md-row d-inline-flex align-items-center results-overview_bot-item"
              style={!this.state.USDBreakdownOpen && this.state.CDNBreakdownOpen ? { 'backgroundColor': 'transparent' } : {}}
            >
              <div className="col results-overview_label">
                {`${(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[1]) && this.state.currentEarnings.yearInDetails[1].localCurrency} Earnings`}
                <Default>
                  <div onClick={this.toggleUSDBreakdown.bind(this)} className="table_view-note-btn">
                    <span>More Details</span>
                    <img src={iconDownArrow} />
                  </div>
                </Default>
              </div>
              <div className="col results-overview_num">
                {`$ ${(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[1]) && this.state.currentEarnings.yearInDetails[1].localEarningTtl}`}
                <Mobile>
                  <div onClick={this.toggleUSDBreakdown.bind(this)} className="table_view-note-btn">
                    <span>More Details</span>
                    <img src={iconDownArrow} />
                  </div>
                </Mobile>
              </div>
            </div>
          </div>
          {this.state.CDNBreakdownOpen &&
            <section className="row justify-content-center results-overview_breakdown">
              <div className="col col-md-9">
                <h2 className="results-overview_breakdown-title text-center text-md-left">{`${this.state.currentEarnings.yearInDetails[0].localCurrency} Earnings Breakdown`}</h2>
                <div className="results-overview_breakdown-sub-title text-center text-md-left">{/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque ullam eum. */}</div>
                <div className="row no-gutters justify-content-between results-overview_col-labels">
                  <div className="col-4 col-md-auto results-overview_col-label">Date</div>
                  <div className="col">
                  </div>
                  <div className="col-4 col-md-auto results-overview_col-label is-name-col">Horse</div>
                  <div className="col">
                  </div>
                  <div className="col-4 col-md-auto results-overview_col-label">Earnings</div>
                </div>
                <div className="results-overview_scroll-wrap">
                  {(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[0]) && this.state.currentEarnings.yearInDetails[0].details.map((item, idx) => (
                    <div key={idx} className="row no-gutters tally_list-row justify-content-between">
                      <div className="col-4 col-md-auto tally_list-val-col">{`${moment(item.raceDate).format('MMM D[,] YYYY')}`}</div>
                      <div className="col tally_list-dot-col">
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        </div>
                      <div className="col-4 col-md-auto tally_list-val-col is-name-col">{item.horse}</div>
                      <div className="col tally_list-dot-col">
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        </div>
                      <div className="col-4 col-md-auto tally_list-val-col"><span className="dollar-sign-margin">$</span>{item.earning}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          }
          {this.state.USDBreakdownOpen &&
            <section className="row justify-content-center results-overview_breakdown">
              <div className="col col-md-9">
                <h2 className="results-overview_breakdown-title text-center text-md-left">{`${this.state.currentEarnings.yearInDetails[0].localCurrency} Earnings Breakdown`}</h2>
                <div className="results-overview_breakdown-sub-title text-center text-md-left">{/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque ullam eum. */}</div>
                <div className="row no-gutters justify-content-between results-overview_col-labels">
                  <div className="col-4 col-md-auto results-overview_col-label">Date</div>
                  <div className="col">
                  </div>
                  <div className="col-4 col-md-auto results-overview_col-label">Horse</div>
                  <div className="col">
                  </div>
                  <div className="col-4 col-md-auto results-overview_col-label">Earnings</div>
                </div>
                <div className="results-overview_scroll-wrap">
                  {(this.state.currentEarnings !== null && this.state.currentEarnings.yearInDetails[1]) && this.state.currentEarnings.yearInDetails[1].details.map((item, idx) => (
                    <div key={idx} className="row no-gutters tally_list-row justify-content-between">
                      <div className="col-4 col-md-auto tally_list-val-col">{`${moment(item.raceDate).format('MMM D[,] YYYY')}`}</div>
                      <div className="col tally_list-dot-col">
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        </div>
                      <div className="col-4 col-md-auto tally_list-val-col">{item.horse}</div>
                      <div className="col tally_list-dot-col">
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        </div>
                      <div className="col-4 col-md-auto tally_list-val-col"><span className="dollar-sign-margin">$</span>{item.earning}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          }
        </div>
      </section>
    )
  }
}

export default ResultsOverview