import React, { Component } from 'react'
import { Default, Mobile } from '../_mediaqueries'
import Swiper from 'react-id-swiper/lib/custom'
import 'react-id-swiper/src/styles/css/swiper.css'

const swiperParams = {
  spaceBetween: 15,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
}

const HorseLocationTally = props => (
  <section className="tally">
    <div className="container-fluid">
      <Default>
        <div className="row gutters-20px tally_main-row">
          <div className="col-12 col-md-4">
            <div className="tally_main-total-wrap d-flex justify-content-between">
              <div className="tally_total-label is-main d-inline-flex align-items-center">
                Total Horses
            </div>
              <div className="tally_total-val is-main">
                {props.tallyData.HorseTtl}
              </div>
            </div>

            {props.tallyData.items && props.tallyData.items.map((item, idx) => (
              <div key={idx} className="tally_regional-total-wrap">
                <div className="tally_regional-total-item d-flex justify-content-between">
                  <div className="tally_total-label is-regional d-inline-flex align-items-center">
                    {`Total ${item.country}`}
                  </div>
                  <div className="tally_total-val is-regional">
                    {item.total}
                  </div>
                </div>
              </div>
            ))}

          </div>

          {props.tallyData.items && props.tallyData.items.map((item, idx) => (
            <div key={idx} className="col-12 col-md-4 tally_barns-wrap">
              <h2 className="text-center tally_barns-title">{item.country}</h2>
              <div className="tally_barns-list">
                {item.location_list.map((innerItem, idx) => (
                  <div key={idx} className="row no-gutters tally_list-row justify-content-between">
                    <div className="col-auto">{innerItem.location}</div>
                    <div className="col tally_list-dot-col">
                      . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div className="col-auto text-right tally_list-val-col">{innerItem.count}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}

        </div>
      </Default>
      <Mobile>
        <Swiper {...swiperParams}>
          <div>
            <div className="tally_main-total-wrap d-flex justify-content-between">
              <div className="tally_total-label is-main d-inline-flex align-items-center">
                Total Horses
            </div>
              <div className="tally_total-val is-main">
                {props.tallyData.HorseTtl}
              </div>
            </div>


            {props.tallyData.items && props.tallyData.items.map((item, idx) => (
              <div key={idx} className="tally_regional-total-wrap">
                <div className="tally_regional-total-item d-flex justify-content-between">
                  <div className="tally_total-label is-regional d-inline-flex align-items-center">
                    {`Total ${item.country}`}
                  </div>
                  <div className="tally_total-val is-regional">
                    {item.total}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {props.tallyData.items && props.tallyData.items.map((item, idx) => (
            <div key={idx} className="tally_barns-wrap">
              <h2 className="text-center tally_barns-title">{item.country}</h2>
              <div className="tally_barns-list">
                {item.location_list.map((innerItem, idx) => (
                  <div key={idx} className="row no-gutters tally_list-row justify-content-between">
                    <div className="col-auto">{innerItem.location}</div>
                    <div className="col tally_list-dot-col">
                      . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                      </div>
                    <div className="col-auto text-right tally_list-val-col">{innerItem.count}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}


        </Swiper>
      </Mobile>
      <hr />
    </div>
  </section>
)

export default HorseLocationTally