import React, { Component } from 'react'

class PredictiveSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: '',
      predictions: []
    }

    this.MASTER_TABLE_ENTITES = []
    this.searchTermResetPending = false
    this.searchInputRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.activeTableData).length && this.props.activeTableData !== prevProps.activeTableData) {
      this.MASTER_TABLE_ENTITES = [...this.props.activeTableData]
    }
  }

  handleTermChange(e) {
    if (this.searchTermResetPending) {
      this.props.resetActiveSearchTerm()
      this.searchTermResetPending = false
    }
    this.setState({
      searchTerm: e.target.value
    }, () => {
      if (this.state.searchTerm.length) {
        this.generatePredictions()
      } else {
        this.resetPredictions()
      }
    })
  }

  generatePredictions() {
    let predictions = []
    this.props.activeTableData.forEach((item) => {
      let name = item.tableCells[0]
      const found = name.toLowerCase().match(this.state.searchTerm.toLowerCase().trim())
      if (found) {
        const html = name.replace(new RegExp(`(${found[0].split('').join('')})`, 'gi'), '<strong>$&</strong>')
        predictions.push(
          {
            text: {
              __html: html
            },
            name
          }
        )
      }
    })

    this.setState({
      predictions
    })
  }

  handleSearchKeyDown(e) {
    if (e.which === 13 || e.which === 40 || e.which === 38) {
      e.preventDefault()
    }

    const predictions = Array.prototype.slice.call(document.querySelectorAll('.search_prediction'))

    if (e.which === 13 && predictions.indexOf(document.activeElement) > -1) {
      const term = predictions[predictions.indexOf(document.activeElement)].getAttribute('data-name')
      this.handlePredictionSelect(term)
    }

    if (e.which === 40) {
      if (document.activeElement == this.searchInputRef.current) {
        predictions[0].focus()
      } else {
        let activeIdx = predictions.indexOf(document.activeElement)
        if (predictions[activeIdx + 1] !== undefined) {
          predictions[++activeIdx].focus()
        }
      }
    }

    if (e.which === 38) {
      if (document.activeElement == this.searchInputRef.current ||
        predictions.indexOf(document.activeElement) === 0) {
        return
      } else {
        let activeIdx = predictions.indexOf(document.activeElement)
        predictions[--activeIdx].focus()
      }
    }
  }

  handlePredictionSelect(term) {
    const searchTerm = typeof term === 'object' ? term.currentTarget.getAttribute('data-name') : term
    this.setState({
      searchTerm: searchTerm,
      predictions: []
    })
    this.props.setActiveSearchTerm(searchTerm)
    this.searchTermResetPending = true
  }

  resetPredictions() {
    this.setState({
      predictions: [],
      searchTerm: ''
    })
  }

  resetTable() {
    this.resetPredictions()
    this.props.resetTableFromTab()
  }

  predictionsMaps(item, idx) {
    return (
      <div key={idx} tabIndex="0" onClick={this.handlePredictionSelect.bind(this)} className="search_prediction"
        data-name={item.name} dangerouslySetInnerHTML={item.text}>
      </div>
    )
  }

  render() {
    return (
      <section className="search">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div onKeyDown={this.handleSearchKeyDown.bind(this)} className="col-12 col-md-8">
              <input ref={this.searchInputRef} onChange={this.handleTermChange.bind(this)} value={this.state.searchTerm} type="text" className="search_input" placeholder="Search for a horse" />
              {this.state.searchTerm !== '' && <button onClick={this.resetTable.bind(this)} type="button" className="search_clear-btn" />}
              {this.state.predictions.length > 0 &&
                <div className="search_predictions">
                  {this.state.predictions.map(this.predictionsMaps.bind(this))}
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PredictiveSearch