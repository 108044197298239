import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import moment from 'moment'

import { fetchHomeEventsByMonth } from '../../_actions/page-home.actions'
import { userLogout } from '../../_actions/logout.actions';

import PageHeader from '../../_components/PageHeader'
import EventsCalendar from '../../_components/EventsCalendar'
import LoadingSplash from '../../_components/LoadingSplash'

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventsData: [],
      isLoading: true
    }

    this.fetchHomeEventsByMonth = this.fetchHomeEventsByMonth.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (
        this.props.pageHomeData.events.needsInit ||
        this.props.pageHomeData.events.error
      ) {
      const date = moment();
      const year = date.year();
      const month = date.format('MM');
      await this.props.dispatch(fetchHomeEventsByMonth(year, month))
    }
    
    if (this.props.pageHomeData.events.error) {
      if (this.props.pageHomeData.events.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }
  }

  fetchHomeEventsByMonth(year, month) {
    this.props.dispatch(fetchHomeEventsByMonth(year, month))
  }

  render() {
    const { loading } = this.props.pageHomeData.events;
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Home'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Calendar'} />
            {loading ? <LoadingSplash/> : null }
              <div style={loading ? {
                  opacity: 0
                } : {}}>
                <EventsCalendar
                  fetchHomeEventsByMonth={this.fetchHomeEventsByMonth}
                  eventsData={this.props.pageHomeData.events}
                  title={'Races this month'}
                />
              </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageHomeData } = state
  return {
    pageHomeData
  }
}

export default connect(mapStateToProps)(HomePage)