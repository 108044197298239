import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { fetchEvents } from '../../_actions/page-events.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import UpcomingEventsList from '../../_components/UpcomingEventsList'
import LoadingSplash from '../../_components/LoadingSplash'

class UpcomingEventsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (
      this.props.pageEventsData.events.needsInit ||
      this.props.pageEventsData.events.error
    ) {
      await this.props.dispatch(fetchEvents())
    }

    if (this.props.pageEventsData.events.error) {
      if (this.props.pageEventsData.events.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Upcoming Events'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Upcoming Events'} />
            <UpcomingEventsList data={this.props.pageEventsData.events.length > 0 && this.props.pageEventsData.events} />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageEventsData } = state
  return {
    pageEventsData
  }
}

export default connect(mapStateToProps)(UpcomingEventsPage)