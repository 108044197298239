import React, { Component } from 'react'
import moment from 'moment'
import { handleViewDetailsUpcoming } from '../_utils'
import iconDownArrow from '../../_img/icon-arrow-down.svg'
import iconClose from '../../_img/icon-close.svg'
import ViewPDFBtn from '../EventsCalendar/ViewPDFBtn'

class UpcomingEventsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imgViewerUrl: ''
    }

    this.expandImgThumb = this.expandImgThumb.bind(this)
    this.closeImgThumb = this.closeImgThumb.bind(this)
  }

  expandImgThumb(e) {
    var target = e.currentTarget;
    this.setState({
      imgViewerUrl: target.getAttribute('src')
    })
  }

  closeImgThumb(e) {
    if (e.target.classList.contains('image')) {
      e.stopPropagation();
      return;
    }
    this.setState({
      imgViewerUrl: ''
    })
  }

  render() {
    return (
      <section className="events-list">
        {this.state.imgViewerUrl !== '' &&
          <div className="events-list_img-viewer" onClick={this.closeImgThumb}>
            <img className="close" onClick={this.closeImgThumb} src={iconClose} />
            <img className="image" src={this.state.imgViewerUrl} />
          </div>
        }  
        <div className="container-fluid">
          {this.props.data && this.props.data.map((item, idx) => {
            return (
              <div key={idx} className="events-list_item">

                <div className="row no-gutters">
                  <div className="col-12 col-md-3 d-inline-flex justify-content-center align-items-center events-list_img-wrap">
                    <img className="events-list_img" src={item.eventImage} />
                  </div>

                  <div className="col-12 col-md events-list_top">
                    <div className="col d-flex flex-column events-list_main-text-wrap">
                      <h2 className="events-list_title">{item.heading}</h2>
                      <div className="events-list_meta-wrap">
                        <p>{moment(item.eventDate).format('MMM D[,] YYYY')}</p>
                        <p>{item.eventTime}</p>
                      </div>
                      <div className="events-list_body"
                        dangerouslySetInnerHTML={{ __html: item.intro }}></div>
                      <div onClick={handleViewDetailsUpcoming} className="table_view-note-btn">
                        <span>More Details</span>
                        <img src={iconDownArrow} />
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>

                <div className="row no-gutters">
                  <div className="col-3 d-none d-md-block events-list_img-wrap"></div>
                  <div className="col">
                    <div className="table_item-note">
                      <div className="table_note-body"
                        dangerouslySetInnerHTML={{ __html: item.details }}></div>
                        {item.eventDocList.length > 0 && 
                          <div className="events-list_pdf-wrap">
                            {item.eventDocList.map(doc => {
                              if (/.pdf$/.test(doc.file)) {
                                return <ViewPDFBtn PDF={doc.file} />
                              } else {
                                return <img className="events-list_img-thumb" onClick={this.expandImgThumb} src={doc.file} />
                              }  
                            })}
                          </div>
                        }
                    </div>
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      </section>
    )
  }  
}

export default UpcomingEventsList