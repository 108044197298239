import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import {
  fetchLocationTally,
  fetchLocationFilters,
  fetchLocationTable, 
  fetchLocationTableCw,
  fetchLocationTableNotCw
} from '../../_actions/page-location.actions.js'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import HorseLocationTally from '../../_components/HorseLocationTally'
import CheckboxFilters from '../../_components/CheckboxFilters'
import HorseLocationTable from '../../_components/HorseLocationTable'
import LoadingSplash from '../../_components/LoadingSplash'

class HorseLocationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeCheckboxFilters: {},
      isLoading: true,
      isMobile: window.matchMedia('(max-width: 767px)').matches
    }

    this.tableRef = React.createRef();
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 767px)').matches
    })
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.checkIfMobile)

    if (
      this.props.pageLocationData.tally.needsInit ||
      this.props.pageLocationData.tableFilters.needsInit ||
      this.props.pageLocationData.table.needsInit ||
      this.props.pageLocationData.tableCw.needsInit ||
      this.props.pageLocationData.tableNotCw.needsInit ||
      this.props.pageLocationData.tally.error ||
      this.props.pageLocationData.tableFilters.error ||
      this.props.pageLocationData.table.error ||
      this.props.pageLocationData.tableCw.error ||
      this.props.pageLocationData.tableNotCw.error
    ) {
      await Promise.all([
        this.props.dispatch(fetchLocationTally()),
        this.props.dispatch(fetchLocationFilters()),
        this.props.dispatch(fetchLocationTable()),
        this.props.dispatch(fetchLocationTableCw()),        
        this.props.dispatch(fetchLocationTableNotCw())
      ])
    }

    if (
      this.props.pageLocationData.tally.error ||
      this.props.pageLocationData.tableFilters.error ||
      this.props.pageLocationData.table.error ||
      this.props.pageLocationData.tableCw.error ||
      this.props.pageLocationData.tableNotCw.error
    ) {
      if (
        this.props.pageLocationData.tally.error === 401 ||
        this.props.pageLocationData.tableFilters.error === 401 ||
        this.props.pageLocationData.table.error === 401 ||
        this.props.pageLocationData.tableCw.error === 401 ||
        this.props.pageLocationData.tableNotCw.error === 401
      ) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })

      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  setActiveCheckboxFilters(activeCheckboxFilters) {
    this.setState({
      activeCheckboxFilters
    })
  }

  resetCheckboxFilters() {
    this.tableRef.current.resetFilters()
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Horse Location'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Horse Location'} />
            <HorseLocationTally tallyData={this.props.pageLocationData.tally ? this.props.pageLocationData.tally : null} />
            <CheckboxFilters tableFiltersData={this.props.pageLocationData.tableFilters ? this.props.pageLocationData.tableFilters : null}
              setActiveFilters={this.setActiveCheckboxFilters.bind(this)}
              resetFilters={this.resetCheckboxFilters.bind(this)}
              isMobile={this.state.isMobile}
            />
            <HorseLocationTable ref={this.tableRef}
              tableData={this.props.pageLocationData.table ? this.props.pageLocationData.table : null}
              tableCwData={this.props.pageLocationData.tableCw ? this.props.pageLocationData.tableCw : null}
              tableNotCwData={this.props.pageLocationData.tableNotCw ? this.props.pageLocationData.tableNotCw : null}
              activeFilters={this.state.activeCheckboxFilters}
              isMobile={this.state.isMobile}
            />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageLocationData } = state
  return {
    pageLocationData
  }
}

export default connect(mapStateToProps)(HorseLocationPage)