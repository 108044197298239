import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import moment from 'moment'

import {
  fetchResultsStats,
  fetchResultsEarnings,
  fetchResultsYears,
  fetchResultsTableByMonth
} from '../../_actions/page-results.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import ResultsOverview from '../../_components/ResultsOverview'
import RacingResultsTable from '../../_components/RacingResultsTable'
import LoadingSplash from '../../_components/LoadingSplash'

class RacingResultsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTab: null,
      overviewData: null,
      racingResults: null,
      isLoading: true,
      isMobile: window.matchMedia('(max-width: 767px)').matches
    }

    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 767px)').matches
    })
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.checkIfMobile)

    if (
      this.props.pageResultsData.stats.needsInit ||
      this.props.pageResultsData.earnings.needsInit ||
      this.props.pageResultsData.table.needsInit ||
      this.props.pageResultsData.stats.error ||
      this.props.pageResultsData.earnings.error ||
      this.props.pageResultsData.table.error
    ) {
      const date = moment();
      const year = date.year();

      await Promise.all([
        this.props.dispatch(fetchResultsStats()),
        this.props.dispatch(fetchResultsEarnings()),
        this.props.dispatch(fetchResultsYears()),
        this.props.dispatch(fetchResultsTableByMonth(year))
      ])
    }

    if (
      this.props.pageResultsData.stats.error ||
      this.props.pageResultsData.earnings.error ||
      this.props.pageResultsData.table.error
    ) {
      if (
        this.props.pageResultsData.stats.error === 401 ||
        this.props.pageResultsData.earnings.error === 401 ||
        this.props.pageResultsData.table.error === 401
      ) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      const currentYear = +this.props.pageResultsData.table.tableEntities[0].year
      const currentTab = this.props.pageResultsData.years.items.indexOf(currentYear) >= 0 
        ? currentYear.toString() 
        : this.props.pageResultsData.years.items[0].toString()

      this.setState({
        isLoading: false,
        currentTab
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }
  }

  setCurrentTab(currentTab) {
    this.props.dispatch(fetchResultsTableByMonth(currentTab))
    this.setState({
      currentTab
    })
  }

  render() {
    const { loading } = this.props.pageResultsData.table;
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Racing Results'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
             {loading ? (
               <LoadingSplash />
             ) : null}
              <div style={loading ? {
                opacity: 0,
                height: '100vh'
              } : {}}>
                <PageHeader heading={'Racing Results'} />
                <ResultsOverview
                  currentTab={this.state.currentTab}
                  statsData={this.props.pageResultsData.stats}
                  earningsData={this.props.pageResultsData.earnings}
                />
                <RacingResultsTable
                  currentTab={this.state.currentTab}
                  years={this.props.pageResultsData.years ? this.props.pageResultsData.years.items : null}
                  setCurrentTab={this.setCurrentTab.bind(this)}
                  tableData={this.props.pageResultsData.table.tableEntities}
                  isMobile={this.state.isMobile}
                />
              </div>
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageResultsData } = state
  return {
    pageResultsData
  }
}

export default connect(mapStateToProps)(RacingResultsPage)