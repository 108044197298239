import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchNewsArticle } from '../../_actions/page-newsarticle.actions'
import { userLogout } from '../../_actions/logout.actions'

import LoadingSplash from '../../_components/LoadingSplash'
import { parseQuery } from '../../_components/_utils'
import ViewPDFBtn from '../../_components/EventsCalendar/ViewPDFBtn'
import iconArrowLeft from '../../_img/icon-arrow-left.svg'
import iconClose from '../../_img/icon-close.svg'

class NewsArticlePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      imgViewerUrl: ''
    }

    this.expandImgThumb = this.expandImgThumb.bind(this)
    this.closeImgThumb = this.closeImgThumb.bind(this)
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    await this.props.dispatch(fetchNewsArticle(parseQuery(this.props.location.search).id))

    if (this.props.pageNewsArticleData.newsArticle.error) {
      if (this.props.pageNewsArticleData.newsArticle.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}${window.location.search}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}${window.location.search}`)
      }
    }
  }

  expandImgThumb(e) {
    var target = e.currentTarget;
    this.setState({
      imgViewerUrl: target.getAttribute('src')
    })
  }
  
  closeImgThumb(e) {
    if (e.target.classList.contains('image')) {
      e.stopPropagation();
      return;
    }
    this.setState({
      imgViewerUrl: ''
    })
  }

  render() {
    const { newsArticle } = this.props.pageNewsArticleData
    return (
      <React.Fragment>
        {this.state.isLoading || newsArticle.needsInit ? <LoadingSplash /> :
          <React.Fragment>
            {this.state.imgViewerUrl !== '' &&
              <div className="events-list_img-viewer" onClick={this.closeImgThumb}>
                <img className="close" onClick={this.closeImgThumb} src={iconClose} />
                <img className="image" src={this.state.imgViewerUrl} />
              </div>
            }  

            <div className="container-fluid back-button">
              <div className="row no-gutters justify-content-center">
                <div className="col col-sm-11 col-md-10 col-lg-8">
                  <button className="d-inline-flex align-items-center back-button_btn" type="button" onClick={this.props.history.goBack}><img src={iconArrowLeft} />Back</button>
                </div>
              </div>
            </div>
            <article>
              <div className="container-fluid">
                <div className="row no-gutters justify-content-center">
                  <div className="col col-sm-11 col-md-10 col-lg-8">
                    <header className="news-article_header">
                      <div className="news-article_date">{moment(newsArticle.newsDate).format('MMM D[,] YYYY')}</div>
                      <h1 className="news-article_title">{newsArticle.title}</h1>
                    </header>
                    <section className="news-article_body">

                      {newsArticle.intro &&
                        <div dangerouslySetInnerHTML={
                          { __html: newsArticle.intro }
                        }></div>
                      }

                      {newsArticle.news_image_wo_exif.url &&
                        <figure className="news-article_img-wrap">
                          <img src={newsArticle.news_image_wo_exif.url} onClick={this.expandImgThumb} />
                          {newsArticle.newsImage.title &&
                            <figcaption>{newsArticle.newsImage.title}</figcaption>
                          }  
                        </figure>
                      }

                      {newsArticle.newsDocList.length > 0 && 
                        <div className="news-article_pdf-wrap">
                          {newsArticle.newsDocList.map(doc => {
                            if (/.pdf$/.test(doc.value.url)) {
                              return <ViewPDFBtn PDF={doc.value.url} />
                            } else {
                              return <img className="events-list_img-thumb" onClick={this.expandImgThumb} src={doc.value.url} />
                            }  
                          })}
                        </div>
                      }

                      {newsArticle.details &&
                        <div dangerouslySetInnerHTML={
                          { __html: newsArticle.details }
                        }></div>
                      }

                      {newsArticle.subtitle &&
                        <h2 className="news-article_subtitle">{newsArticle.subtitle}</h2>
                      }

                      {newsArticle.content &&
                        <div dangerouslySetInnerHTML={
                          { __html: newsArticle.content }
                        }></div>
                      }
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageNewsArticleData } = state
  return {
    pageNewsArticleData
  }
}

export default connect(mapStateToProps)(NewsArticlePage)