export const userLogout = (msgStatus) => {
  return dispatch => {
    localStorage.removeItem('authorization_token');
    localStorage.removeItem('current_username');
    localStorage.removeItem('detailed_report_pw');
    dispatch({
      type: msgStatus === 'NO_MSG' ? 'USER_LOGOUT_NO_MSG' : 'USER_LOGOUT'
    })
  } 
}  
