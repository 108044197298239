import { RSAA } from 'redux-api-middleware'

export const pageNewsfeedConsts = {
  REQUEST: '@@PAGE_NEWSFEED/PAGE_NEWSFEED_REQUEST',
  SUCCESS: '@@PAGE_NEWSFEED/PAGE_NEWSFEED_SUCCESS',
  FAILURE: '@@PAGE_NEWSFEED/PAGE_NEWSFEED_FAILURE',
};

export const fetchNewsfeed = () => ({
  [RSAA]: {
    endpoint: `/api/v2/news/all`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageNewsfeedConsts.REQUEST,
      pageNewsfeedConsts.SUCCESS,
      pageNewsfeedConsts.FAILURE
    ]
  }
})