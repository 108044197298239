import {
  pageBreedingServicingConsts,
  pageBreedingBirthsConsts
} from '../_actions/page-breeding.actions';

const initialState = {
  servicing: { needsInit: true },
  births: { needsInit: true }
}

export function pageBreeding(state = initialState, action) {
  switch (action.type) {
    case pageBreedingServicingConsts.REQUEST:
      return {
        ...state,
        servicing: { loading: true }
      }
    case pageBreedingServicingConsts.SUCCESS:
      return {
        ...state,
        servicing: action.payload
      }
    case pageBreedingServicingConsts.FAILURE:
      return {
        ...state,
        servicing: { error: action.payload.status }
      }

    case pageBreedingBirthsConsts.REQUEST:
      return {
        ...state,
        births: { loading: true }
      }
    case pageBreedingBirthsConsts.SUCCESS:
      return {
        ...state,
        births: action.payload
      }
    case pageBreedingBirthsConsts.FAILURE:
      return {
        ...state,
        births: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}
