import { RSAA } from 'redux-api-middleware'

export const pageHorsesAllConsts = {
  REQUEST: '@@PAGE_HORSES_ALL/PAGE_HORSES_ALL_REQUEST',
  SUCCESS: '@@PAGE_HORSES_ALL/PAGE_HORSES_ALL_SUCCESS',
  FAILURE: '@@PAGE_HORSES_ALL/PAGE_HORSES_ALL_FAILURE',
}

export const fetchHorsesAll = () => ({
  [RSAA]: {
    endpoint: '/api/v2/horse/all',
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageHorsesAllConsts.REQUEST,
      {
        type: pageHorsesAllConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          let horsesAll = {
            horseStates: ['All Horses', 'Current', 'Adopted', 'Sold', 'Retired', 'Deceased'],
            currentTab: 'all horses',
            tableHeadings: ['Horse', 'Year', 'Status', 'Earnings'],
            tableEntities: []
          }
          payload.items.forEach((item) => {
            let entity = {
              tableCells: [
                item.horse,
                item.birthYear,
                item.status,
                item.earningSummary.grandTtl
              ],
              state: item.tabGroup.toLowerCase(),
              detailID: item.id
            }
            horsesAll.tableEntities.push(entity)
          })

          return horsesAll
        }
      },
      pageHorsesAllConsts.FAILURE
    ]
  }
})