import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { fetchLicenses } from '../../_actions/page-license.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import HorseLicenseTable from '../../_components/HorseLicenseTable'
import LoadingSplash from '../../_components/LoadingSplash'

class RacingLicensePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      licenseTable: null,
      isLoading: true,
      isMobile: window.matchMedia('(max-width: 767px)').matches
    }
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.setState({
      isMobile: window.matchMedia('(max-width: 767px)').matches
    })
  }

  async componentDidMount() {
    if (
      this.props.pageLicenseData.licenses.needsInit ||
      this.props.pageLicenseData.licenses.error
    ) {
      await this.props.dispatch(fetchLicenses())
    }

    if (this.props.pageLicenseData.licenses.error) {
      if (this.props.pageLicenseData.licenses.error === 401) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      window.scrollTo(0, 0)
      window.addEventListener('resize', this.checkIfMobile)
      this.setState({
        isLoading: false
      })

      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet title={'Chiefswood Stables | Racing License'}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Racing License'} />
            <HorseLicenseTable
              data={this.props.pageLicenseData.licenses}
              isMobile={this.state.isMobile}
            />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageLicenseData } = state
  return {
    pageLicenseData
  }
}

export default connect(mapStateToProps)(RacingLicensePage)