import { RSAA } from 'redux-api-middleware'

export const pageLoginMsgConsts = {
  REQUEST: '@@PAGE_LOGIN_MSG/PAGE_LOGIN_MSG_REQUEST',
  SUCCESS: '@@PAGE_LOGIN_MSG/PAGE_LOGIN_MSG_SUCCESS',
  FAILURE: '@@PAGE_LOGIN_MSG/PAGE_LOGIN_MSG_FAILURE',
};

export const fetchLoginMsg = () => ({
  [RSAA]: {
    endpoint: `/api/v2/message/welcome`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageLoginMsgConsts.REQUEST,
      pageLoginMsgConsts.SUCCESS,
      pageLoginMsgConsts.FAILURE
    ]
  }
})