import {
  pageResultsStatsConsts,
  pageResultsEarningsConsts,
  pageResultsYearsConsts,
  pageResultsTableConsts
} from '../_actions/page-results.actions';

const initialState = {
  stats: { needsInit: true },
  earnings: { needsInit: true },
  table: { needsInit: true }
}

export function pageResults(state = initialState, action) {
  switch (action.type) {
    case pageResultsStatsConsts.REQUEST:
      return {
        ...state,
        stats: { loading: true }
      }
    case pageResultsStatsConsts.SUCCESS:
      return {
        ...state,
        stats: action.payload
      }
    case pageResultsStatsConsts.FAILURE:
      return {
        ...state,
        stats: { error: action.payload.status }
      }

    case pageResultsEarningsConsts.REQUEST:
      return {
        ...state,
        earnings: { loading: true }
      }
    case pageResultsEarningsConsts.SUCCESS:
      return {
        ...state,
        earnings: action.payload
      }
    case pageResultsEarningsConsts.FAILURE:
      return {
        ...state,
        earnings: { error: action.payload.status }
      }

      case pageResultsYearsConsts.REQUEST:
        return {
          ...state,
          years: { loading: true }
        }
      case pageResultsYearsConsts.SUCCESS:
        return {
          ...state,
          years: action.payload
        }
      case pageResultsYearsConsts.FAILURE:
        return {
          ...state,
          years: { error: action.payload.status }
        }

    case pageResultsTableConsts.REQUEST:
      return {
        ...state,
        table: { loading: true }
      }
    case pageResultsTableConsts.SUCCESS:
      return {
        ...state,
        table: action.payload
      }
    case pageResultsTableConsts.FAILURE:
      return {
        ...state,
        table: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState  
    default:
      return state
  }
}        