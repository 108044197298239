import {
  pageLicenseConsts,
} from '../_actions/page-license.actions';

const initialState = {
  licenses: { needsInit: true }
}

export function pageLicense(state = initialState, action) {
  switch (action.type) {
    case pageLicenseConsts.REQUEST:
      return {
        ...state,
        licenses: { loading: true }
      }
    case pageLicenseConsts.SUCCESS:
      return {
        ...state,
        licenses: action.payload
      }
    case pageLicenseConsts.FAILURE:
      return {
        ...state,
        licenses: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState      
    default:
      return state
  }
}  