import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { sortNamesAlphabetically } from '../_utils'

import iconSortArrow from '../../_img/icon-sort-arrow.svg'

class HorseLocationTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTableEntities: [],
      nameOrderAsc: true
    }

    this.MASTER_TABLE_ENTITES = []
  }

  componentDidMount() {
    this.MASTER_TABLE_ENTITES = [...this.props.tableCwData.tableEntities, ...this.props.tableNotCwData.tableEntities] 
    this.setState({
      currentTableEntities: [...this.props.tableCwData.tableEntities, ...this.props.tableNotCwData.tableEntities]
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeFilters !== prevProps.activeFilters) {
      this.filterTable()
    }
  }

  filterTable() {
    const newEntities = [];
    this.MASTER_TABLE_ENTITES.map((item) => {
      const self = this;
      const subEntities = item.table.filter((item) => {
        let keepItem = true
        Object.keys(item.filters).forEach((key) => {
          
          if (self.props.activeFilters[key].length && self.props.activeFilters[key].indexOf(item.filters[key].replace(' ', '')) === -1) {
            keepItem = false
          }
        })
        return keepItem         
      })
      if (subEntities.length > 0) {
        const entity = {
          headings : item.headings,
          location: item.location,
          inCareOf: item.inCareOf,
          count: item.count,
          table : subEntities
        }
        newEntities.push(entity);
      }
    })

    this.setState({
      currentTableEntities: newEntities
    })
  }

  resetFilters() {
    this.setState({
      currentTableEntities: [...this.MASTER_TABLE_ENTITES]
    })
  }

  handleNameSort() {
    this.setState({
      nameOrderAsc: !this.state.nameOrderAsc,
      currentTableEntities: sortNamesAlphabetically(!this.state.nameOrderAsc, this.state.currentTableEntities)
    })
  }

  tableHeadingMap(item, idx, arr) {
    if (this.props.isMobile && idx > 0) {
      return null
    }

    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
        </div>
      </React.Fragment>
    )
  }

  tableEntitiesMap(item, idx) {
    return (
      <Link to={`/horses/detail?id=${item.detailId}`} label="Horse Details" key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.props.isMobile &&
                    <div>
                      {this.props[item.headings].tableHeadings[idx]}
                    </div>}
                  <div>
                    {entityItem}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
      </Link>
    )
  }

  tableRollUpMap(item, idx){
    return (

      <div className="table">
        <div className="container-fluid">
          <div className="row table_heading-row table_section-row">
            {item.location && <div class="col">Location: {item.location}</div>}
            <div className="col">In Care of: {item.inCareOf}</div>
            <div className="col" style={{float: "right"}}>Total # Horses: {item.table.length}</div>
          </div>
        </div>
          <div className="container-fluid">
            <div className="row table_heading-row">   
               
              {this.props[item.headings].tableHeadings ? this.props[item.headings].tableHeadings.map(this.tableHeadingMap.bind(this)) : null}
            </div>
            {item.table ? item.table.map(this.tableEntitiesMap.bind(this)) : null}
          </div>
      </div>
    )
  }
  render() {
    return (
      <section className="breeding-table">
        {this.state.currentTableEntities ? this.state.currentTableEntities.map(this.tableRollUpMap.bind(this)) : null}
      </section>
    )
  }
}

export default HorseLocationTable