import React, { Component } from 'react'

class HorseLicenseTable extends Component {
  constructor(props) {
    super(props)
  }

  tableHeadingMap(item, idx, arr) {
    if (this.props.isMobile) {
      return null
    }
    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
        </div>
        {idx === arr.length - 1 && <div className="col"></div>}
      </React.Fragment>
    )
  }

  tableEntitiesMap(item, idx) {
    return (
      <div key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex d-md-block justify-content-between">
                  {this.props.isMobile &&
                    <div>{this.props.data.tableHeadings[idx]}</div>
                  }
                  <div>{entityItem}</div>
                </div>
                {idx === arr.length - 1 &&
                  <div className="col license-table_expiry-col">
                    {(item.isExpiringSoon && !item.isExpired) &&
                      <div className="license-table_expiring-soon">
                        Expiring Soon
                      </div>
                    }
                    {item.isExpired &&
                      <div className="license-table_expired">
                        Expired
                      </div>
                    }
                  </div>
                }
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
      </div>
    )
  }

  render() {
    return (
      <section className="license-table">
        <div className="table">
          <div className="container-fluid">
            <div className="row table_heading-row">
              {this.props.data.tableHeadings && this.props.data.tableHeadings.map(this.tableHeadingMap.bind(this))}
            </div>
            {this.props.data.tableEntities && this.props.data.tableEntities.map(this.tableEntitiesMap.bind(this))}
          </div>
        </div>
      </section>
    )
  }
}

export default HorseLicenseTable

