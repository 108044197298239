import {
  pageLocationTallyConsts,
  pageLocationFiltersConsts,
  pageLocationTableConsts,
  pageLocationTableCwConsts,
  pageLocationTableNotCwConsts
} from '../_actions/page-location.actions';

const initialState = {
  tally: { needsInit: true },
  tableFilters: { needsInit: true },
  table: { needsInit: true },
  tableCw: { needsInit: true },
  tableNotCw: { needsInit: true }
}

export function pageLocation(state = initialState, action) {
  switch (action.type) {
    case pageLocationTallyConsts.REQUEST:
      return {
        ...state,
        tally: { loading: true }
      }
    case pageLocationTallyConsts.SUCCESS:
      return {
        ...state,
        tally: action.payload
      }
    case pageLocationTallyConsts.FAILURE:
      return {
        ...state,
        tally: { error: action.payload.status }
      }

    case pageLocationFiltersConsts.REQUEST:
      return {
        ...state,
        tableFilters: { loading: true }
      }
    case pageLocationFiltersConsts.SUCCESS:
      return {
        ...state,
        tableFilters: action.payload
      }
    case pageLocationFiltersConsts.FAILURE:
      return {
        ...state,
        tableFilters: { error: action.payload.status }
      }

    case pageLocationTableConsts.REQUEST:
      return {
        ...state,
        table: { loading: true }
      }
    case pageLocationTableConsts.SUCCESS:
      return {
        ...state,
        table: action.payload
      }
    case pageLocationTableConsts.FAILURE:
      return {
        ...state,
        table: { error: action.payload.status }
      }

    case pageLocationTableCwConsts.REQUEST:
      return {
        ...state,
        tableCw: { loading: true }
      }
    case pageLocationTableCwConsts.SUCCESS:
      return {
        ...state,
        tableCw: action.payload
      }
    case pageLocationTableCwConsts.FAILURE:
      return {
        ...state,
        tableCw: { error: action.payload.status }
      }
  
    case pageLocationTableNotCwConsts.REQUEST:
      return {
        ...state,
        tableNotCw: { loading: true }
      }
    case pageLocationTableNotCwConsts.SUCCESS:
      return {
        ...state,
        tableNotCw: action.payload
      }
    case pageLocationTableNotCwConsts.FAILURE:
      return {
        ...state,
        tableNotCw: { error: action.payload.status }
      }
  
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}