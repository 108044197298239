import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import TableTabs from './TableTabs'

import { sortNamesAlphabetically, handleViewNote } from '../_utils'

import iconSortArrow from '../../_img/icon-sort-arrow.svg'
import iconDownArrow from '../../_img/icon-arrow-down.svg'

class BreedingTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTableEntities: null,
      years: null,
      currentTab: null,
      nameOrderAsc: true
    }

    this.MASTER_TABLE_ENTITES = []
    this.isMobile = null
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentDidMount() {
    this.MASTER_TABLE_ENTITES = [...this.props.tableData.tableEntities]
    this.setState({
      currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableByYear(this.props.tableData.currentTab)),
      years: [...this.props.tableData.years],
      currentTab: this.props.tableData.currentTab
    })
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    window.addEventListener('resize', this.checkIfMobile)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentTab !== null && this.state.currentTab !== prevState.currentTab && Object.keys(prevProps.tableData).length) {
      this.setState({
        currentTableEntities: sortNamesAlphabetically(this.state.nameOrderAsc, this.filterTableByYear())
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    this.forceUpdate()
  }

  filterTableByYear(currentTab = this.state.currentTab) {
    return this.MASTER_TABLE_ENTITES.filter((item) => {
      return item.year === currentTab
    })
  }

  handleNameSort() {
    this.setState({
      nameOrderAsc: !this.state.nameOrderAsc,
      currentTableEntities: sortNamesAlphabetically(!this.state.nameOrderAsc, this.state.currentTableEntities)
    })
  }

  closeAllNotes() {
    [...document.querySelectorAll('.table_item-row.is-active')]
      .forEach(item => {
        item.removeAttribute('style')
        item.classList.remove('is-active')
        item.querySelector('.table_view-note-btn span').innerHTML = 'View Note'
        for (let i = 0; i < item.children.length; i++) {
          item.children[i].removeAttribute('style')
          item.children[i].classList.remove('is-active')
        }
      })
  }

  setCurrentTab(currentTab) {
    this.closeAllNotes()
    this.setState({
      currentTab
    })
  }

  tableHeadingMap(item, idx, arr) {
    if (this.isMobile && idx > 0) {
      return null
    }
    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
          {idx === 0 &&
            <div onClick={this.handleNameSort.bind(this)}
              className={`table_sort-alphabetical-btn${this.state.nameOrderAsc ? ' is-asc' : ' is-desc'}`}>
              <img src={iconSortArrow} />
            </div>}
        </div>
        {(idx === arr.length - 1) &&
          <div className="col"></div>}
      </React.Fragment>
    )
  }

  tableEntitiesMap(item, idx) {
    return (
      <Link to={`/horses/detail?id=${item.detailID}`} label="Horse Details" key={idx} className="table_item-row">
        <div className="row flex-column flex-md-row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-inline-flex justify-content-between">
                  {this.isMobile && <div>{this.props.tableData.tableHeadings[idx]}</div>}
                  <div>
                    {(idx === 4 && item.isEstimatedDate) && <div className="table-tabs_estimated-dot"></div>}
                    {entityItem}
                  </div>
                </div>
                {(idx === arr.length - 1) &&
                  <div onClick={handleViewNote} className="col table_view-note-btn">
                    <span>View Note</span>
                    <img src={iconDownArrow} />
                  </div>}
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
        <div className="table_item-note">
          <div className="pad-x-15px">
            <div className="table_note-label">Notes</div>
            <div className="table_note-body" dangerouslySetInnerHTML={{ __html: item.note }}></div>
          </div>
          <hr />
        </div>
      </Link>
    )
  }

  render() {
    return (
      <section className="breeding-table">
        <TableTabs
          years={this.state.years}
          currentTab={this.state.currentTab}
          setCurrentTab={this.setCurrentTab.bind(this)}
          isMobile={this.props.isMobile}
        />
        <div className="table">
          <div className="container-fluid">
            <div className="row table_heading-row">
              {this.props.tableData.tableHeadings && this.props.tableData.tableHeadings.map(this.tableHeadingMap.bind(this))}
            </div>
            {this.state.currentTableEntities !== null && this.state.currentTableEntities.map(this.tableEntitiesMap.bind(this))}
            {this.state.currentTableEntities !== null && !this.state.currentTableEntities.length &&
              <div className="pad-x-15px table_no-results">
                No records found for selected year
              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default BreedingTable