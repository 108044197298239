import React, { Component } from 'react'
import { Default, Mobile } from '../_mediaqueries'
import Swiper from 'react-id-swiper/lib/custom'

import iconArrowRight from '../../_img/icon-arrow-right.svg'
import iconArrowLeft from '../../_img/icon-arrow-left.svg'

const swiperParams = {
  slidesPerView: 10,
  slidesOffsetAfter: 0,
  slideToClickedSlide: true,
  preventClicks: true,
  grabCursor: true,
  centeredSlides: true,
  initialSlide: 2,
  shouldSwiperUpdate: true,
  breakpoints: {
    767: {
      slidesPerView: 3,
    },
  },
}

class TableTabs extends Component {

  yearsTabsMap(item) {
    return (
      <button key={item} data-year={item} type="button"
        onClick={this.handleTabClick.bind(this)}
        className={`table-tabs_year-tab${this.props.currentTab === item ? ' is-active' : ''}`}>
        {item}
      </button>
    )
  }

  handleTabClick(e) {
    this.props.setCurrentTab(e.target.getAttribute('data-year'))
  }

  handleArrowClick(e) {
    let currentIdx = this.props.years.indexOf(this.props.currentTab)

    if (e.currentTarget.getAttribute('data-dir') == 'next') {
      (currentIdx + 1) > this.props.years.length - 1 ? currentIdx = 0 : currentIdx++
    } else {
      (currentIdx - 1) < 0 ? currentIdx = this.props.years.length - 1 : currentIdx--
    }

    if (this.swiper) {
      this.swiper.slideTo(currentIdx)
    }

    this.props.setCurrentTab(this.props.years[currentIdx])
  }

  render() {
    return (
      <div className="container-fluid table-tabs">
        <Mobile>
          <div className="row justify-content-between">
            <div className="col-auto d-inline-flex align-items-center table-tabs_heading">
              Births
            </div>
            <div className="col-auto d-inline-flex align-items-center">
              <div className="table-tabs_estimated-dot"></div>
              <div className="table-tabs_estimated-label">Estimated<br /> Date</div>
            </div>
          </div>
        </Mobile>
        <div className="row table-tabs_row-1">
          <Default>
            <div className="col-auto d-inline-flex align-items-center table-tabs_heading">
              Births
            </div>
          </Default>

          <button onClick={this.handleArrowClick.bind(this)} data-dir="prev" type="button" className="col-auto table-tabs_arrow-btn">
            <img src={iconArrowLeft} />
          </button>

          <Swiper {...swiperParams} ref={node => (this.swiper = node !== null ? node.swiper : null)}>
            {this.props.years !== null && this.props.years.map(this.yearsTabsMap.bind(this))}
          </Swiper>

          <button onClick={this.handleArrowClick.bind(this)} data-dir="next" type="button" className="col-auto table-tabs_arrow-btn">
            <img src={iconArrowRight} />
          </button>

          <Default>
            <div className="col-auto d-inline-flex align-items-center">
              <div className="table-tabs_estimated-dot"></div>
              <div className="table-tabs_estimated-label">Estimated<br /> Date</div>
            </div>
          </Default>

        </div>
        <hr className="table-tabs_border-bot" />
      </div>
    )
  }
}

export default TableTabs