import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { fetchLoginMsg } from '../../_actions/page-login.actions'
import { userLogout } from '../../_actions/logout.actions'

import LoadingSplash from '../../_components/LoadingSplash'
import LogInSplash from '../../_components/LogInSplash'
import LogInForm from '../../_components/LogInForm'
import Footer from '../../_components/Footer'

import { parseQuery } from '../../_components/_utils';

import BGImage from '../../_img/loginBG.jpg'
import navLogo from '../../_img/chiefswood-white-logo.png'

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      logInFormVisible: false,
      loginExpiredMsg: false,
      loggedOutMsg: false
    }

    this.referrer = null;
    this.handleLogin = this.handleLogin.bind(this)
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (localStorage.getItem('authorization_token')) {
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token);
        this.props.history.push('/home');
      }  else {
        this.props.dispatch(userLogout());
      }
    }

    if (this.props.pageLoginData.msg.needsInit) {
      await this.props.dispatch(fetchLoginMsg())
    }

    this.setState({
      isLoading: false,
      incorrectCreds: false
    })

    if (this.props.pageLoginData.sessionExpired) {
      setTimeout(() => {
        this.setState({
          loginExpiredMsg: true
        })
      })  
    } else if (this.props.pageLoginData.loggedOut) {
      setTimeout(() => {
        this.setState({
          loggedOutMsg: true
        })
      })  
    }

    if (parseQuery(this.props.location.search).pwreset) {
      this.setState({
        logInFormVisible: true
      })
    }

    if (parseQuery(this.props.location.search).referrer) {
      this.referrer = parseQuery(this.props.location.search).referrer
    }
  }

  toggleLogInFormVisible() {
    this.setState({
      logInFormVisible: !this.state.logInFormVisible
    }, () => {
      document.querySelector('.login-form_text-input[type="text"]').focus();
    })
  }

  async handleLogin(username, password) {
    this.setState({
      incorrectCreds: false
    })

    const body = { username, password }
    const res = await fetch('/rest-auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const token = await res.json()
    if (token.token) {
      localStorage.setItem('authorization_token', token.token);
      localStorage.setItem('current_username', username);
      setTimeout(() => {
        if (this.referrer) {
          this.props.history.push(`${this.referrer}`)
        } else {
          this.props.history.push('/home');
        }
      })  
    } else {
      this.setState({
        incorrectCreds: true
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet 
          title={'Chiefswood Stables | Login'}   
          meta={[
            {
              name: 'description',
              content: 'Chiefswood Stables members login.',
            },
          ]}/>
        {this.state.isLoading ? <LoadingSplash /> :
          <div className="login" style={{ backgroundImage: `url(${BGImage})` }}>
            <CSSTransition in={this.state.loginExpiredMsg}
              timeout={300}
              classNames="login_expired-msg"
              unmountOnExit
            >
              <div className="login_expired-msg">
                <p>Your session has expired.</p>
                <p>Please login again.</p>
              </div>  
            </CSSTransition>
            <CSSTransition in={this.state.loggedOutMsg}
              timeout={300}
              classNames="login_expired-msg"
              unmountOnExit
            >
              <div className="login_expired-msg">
                <p>You have been logged out.</p>
              </div>  
            </CSSTransition>
            <div className="container-fluid">
              <div className="row no-gutters login_top-row">
                <div className="col-auto">
                  <img src={navLogo} />
                </div>
              </div>
            </div>

            <LogInForm search={this.props.location.search} incorrectCreds={this.state.incorrectCreds} handleLogin={this.handleLogin} in={this.state.logInFormVisible} />

            {!this.state.logInFormVisible && <LogInSplash msg={this.props.pageLoginData.msg} toggleLogInFormVisible={this.toggleLogInFormVisible.bind(this)} />}

            {this.state.logInFormVisible && <Footer />}
          </div>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageLoginData } = state
  return {
    pageLoginData
  }
}

export default connect(mapStateToProps)(LoginPage)