import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group';
import { Mobile } from '../_mediaqueries'
import { parseQuery } from '../_utils'

import logoChiefswoodBlack from '../../_img/chiefswood-black-logo.png'

class LogInForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 'login',
      useremail: '',
      username: '',
      password: '',
      newPassword1: '',
      newPassword2: '',
      emailEmptyError: false,
      newPWError: false
    }

    this.newPasswordUID = null;
    this.newPasswordToken = null;

    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotEmail = this.handleForgotEmail.bind(this);
    this.backToLoginForm = this.backToLoginForm.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
  }

  componentDidMount() {
    if (parseQuery(this.props.search).pwreset) {
      this.setState({ step: 'pw_reset' })
      this.newPasswordUID = parseQuery(this.props.search).uid;
      this.newPasswordToken = parseQuery(this.props.search).token;
    }
 
    window.addEventListener('keydown', this.handleEnterPress)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnterPress)
  }

  handleEnterPress(e) {
    if (e.which === 13) {
      switch(this.state.step) {
        case 'login':
          this.handleLogin();
          break;
        case 'forgot_1':
          this.handleForgotEmail();
          break;
        case 'forgot_end':
          this.backToLoginForm();
          break;
        case 'pw_reset':
          this.handleNewPassword();
          break;
        case 'pw_reset_end':
          this.backToLoginForm();
          break;
      }
    }
  }

  async handleForgotEmail() {
    const body = { email: this.state.useremail }
    const res = await fetch('/rest-auth/password/reset/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const resJson = await res.json();

    if (res.status === 200) {
      this.setState({
        emailEmptyError: false,
        step: 'forgot_end'
      })
    } else {
      this.setState({
        emailEmptyError: resJson.email[0]
      })
    }
  }

  handleLogin() {
    this.props.handleLogin(this.state.username, this.state.password)
  }

  async handleNewPassword() {
    const body = { 
      uid: this.newPasswordUID,
      token: this.newPasswordToken,
      new_password1: this.state.newPassword1,
      new_password2: this.state.newPassword2
    }
    const res = await fetch('/rest-auth/password/reset/confirm/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    const resJson = await res.json();

    if (res.status === 200) {
      this.setState({
        newPW1Error: false,
        newPW2Error: false,
        step: 'pw_reset_end'
      })
    } else {
      if (resJson.token) {
        this.setState({
          newPW2Error: ['This reset link has expired.']
        })
      } else {
        this.setState({
          newPW1Error: resJson.new_password1,
          newPW2Error: resJson.new_password2,
        })
      }
    }
  }

  backToLoginForm() {
    this.setState({ step: 'login', useremail: '' })
  }

  render() {
    return (
      <CSSTransition in={this.props.in}
        timeout={300}
        classNames="login-form"
        unmountOnExit
      >
        <section className="login-form">
          <div className="container-fluid login-form_main-container">
            <Mobile>
              <div className="row justify-content-center align-items-center login-form_mobile-logo">
                <img src={logoChiefswoodBlack} />
              </div>
            </Mobile>

            <CSSTransition in={this.state.step === 'login'}
              timeout={300}
              classNames="step-login"
              unmountOnExit
            >
              <div className="row no-gutters flex-column text-center login-form_column">
                <div className="col-auto login-form_banner">
                  <h1 className="login-form_heading">Login</h1>
                  <div className="login-form_copy">
                    <p>Enter your credentials below to access our database.</p>
                  </div>
                </div>
                <div className="col login-form_inputs-wrap">
                  <input className="login-form_text-input" type="text" placeholder="Username" value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }) }} />
                  <input className="login-form_text-input" type="password" placeholder="Password" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                  <div className="login-form_additional">
                    <div className="login-form_checkbox-wrap">
{/*                       <input type="checkbox" className="login-form_checkbox" id="rememberMe" />
                      <label htmlFor="rememberMe"></label>
                      <div className="login-form_checkbox-label">Remember Me</div> */}
                    </div>
                    <button className="login-form_forgot-pass"
                      onClick={() => { this.setState({ step: 'forgot_1' }) }}
                    >Forgot Password</button>
                  </div>
                  {this.props.incorrectCreds ? <div className="login-form_incorrect">Incorrect username or password.</div> : <div className="login-form_incorrect">&nbsp;</div>}
                </div>
                <div className="col-auto text-center login-form_btn-wrap">
                  <button onClick={this.handleLogin} type="button" className="login-form_login-btn">Login</button>
                </div>
                <div className="col-auto login-form_disclaimer">
                  By signing in, you agree to Chiefswood’s <strong>Terms and Conditions</strong> & <strong>Privacy Policy</strong>.
                </div>
              </div>
            </CSSTransition>

            <CSSTransition in={this.state.step === 'forgot_1'}
              timeout={300}
              classNames="step-login"
              unmountOnExit
            >
              <div className="row no-gutters flex-column text-center login-form_column step-forgot-1">
                <div className="col-auto login-form_banner">
                  <h1 className="login-form_heading">Reset Password</h1>
                  <div className="login-form_copy">
                    <p>Enter your registed email below and a link to reset your password will be sent to you shortly.</p>
                  </div>
                </div>
                <div className="col login-form_inputs-wrap">
                  <input className="login-form_text-input" type="text" placeholder="Email" value={this.state.useremail} onChange={(e) => { this.setState({ useremail: e.target.value }) }} />
                  {this.state.emailEmptyError ? <div className="login-form_incorrect">{this.state.emailEmptyError}</div> : <div className="login-form_incorrect">&nbsp;</div>}
                  <div className="col-auto text-center login-form_btn-wrap">
                    <button className="login-form_login-btn"
                      onClick={this.handleForgotEmail}>Send</button>
                  </div>
                </div>

              </div>
            </CSSTransition>

            <CSSTransition in={this.state.step === 'forgot_end'}
              timeout={300}
              classNames="step-login"
              unmountOnExit
            >
              <div className="row no-gutters flex-column text-center login-form_column step-forgot-end">
                <div className="col-auto login-form_banner">
                  <h1 className="login-form_heading">Check Your Mail</h1>
                  <div className="login-form_copy">
                    <p>An Email was sent to:<br /> {this.state.useremail}</p>
                    <p>Click the link in the email to reset your password.</p>
                  </div>
                </div>
                <div className="col login-form_inputs-wrap">
                  <div className="col-auto text-center">
                    <button className="login-form_login-btn login-form_btn-wrap"
                      onClick={this.backToLoginForm}>Back to Login</button>
                  </div>
                </div>

              </div>
            </CSSTransition>

            <CSSTransition in={this.state.step === 'pw_reset'}
              timeout={300}
              classNames="step-login"
              unmountOnExit
            >
              <div className="row no-gutters flex-column text-center login-form_column step-forgot-1">
                <div className="col-auto login-form_banner">
                  <h1 className="login-form_heading">Reset Password</h1>
                  <div className="login-form_copy">
                    <p>Enter your new password.</p>
                  </div>
                </div>
                <div className="col login-form_inputs-wrap">
                  <input className="login-form_text-input" type="password" placeholder="Enter your new password" value={this.state.newPassword1} onChange={(e) => { this.setState({ newPassword1: e.target.value }) }} />
                  <div>{this.state.newPW1Error ? this.state.newPW1Error.map(error => <div className="login-form_incorrect is-margin-bot">{error}</div>) : <div className="login-form_incorrect is-margin-bot">&nbsp;</div>}</div>
                  <input className="login-form_text-input" type="password" placeholder="Confirm your new password" value={this.state.newPassword2} onChange={(e) => { this.setState({ newPassword2: e.target.value }) }} />
                  <div>{this.state.newPW2Error ? this.state.newPW2Error.map(error => <div className="login-form_incorrect is-margin-bot">{error}</div>) : <div className="login-form_incorrect is-margin-bot">&nbsp;</div>}</div>
                  <div className="col-auto text-center login-form_btn-wrap">
                    <button className="login-form_login-btn"
                      onClick={this.handleNewPassword}>Send</button>
                  </div>
                </div>

              </div>
            </CSSTransition>

            <CSSTransition in={this.state.step === 'pw_reset_end'}
              timeout={300}
              classNames="step-login"
              unmountOnExit
            >
              <div className="row no-gutters flex-column text-center login-form_column step-forgot-end">
                <div className="col-auto login-form_banner">
                  <h1 className="login-form_heading">Your password has been reset</h1>
                  <div className="login-form_copy">
                    <p>You can now login using your new password.</p>
                  </div>
                </div>
                <div className="col login-form_inputs-wrap">
                  <div className="col-auto text-center">
                    <button className="login-form_login-btn login-form_btn-wrap"
                      onClick={this.backToLoginForm}>Back to Login</button>
                  </div>
                </div>

              </div>
            </CSSTransition>

          </div>
        </section>
      </CSSTransition>
    )
  }
}

export default LogInForm