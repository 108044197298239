import { RSAA } from 'redux-api-middleware'
import moment from 'moment'

export const pageResultsStatsConsts = {
  REQUEST: '@@PAGE_RESULTS_STATS/PAGE_RESULTS_STATS_REQUEST',
  SUCCESS: '@@PAGE_RESULTS_STATS/PAGE_RESULTS_STATS_SUCCESS',
  FAILURE: '@@PAGE_RESULTS_STATS/PAGE_RESULTS_STATS_FAILURE',
}

export const pageResultsEarningsConsts = {
  REQUEST: '@@PAGE_RESULTS_EARNINGS/PAGE_RESULTS_EARNINGS_REQUEST',
  SUCCESS: '@@PAGE_RESULTS_EARNINGS/PAGE_RESULTS_EARNINGS_SUCCESS',
  FAILURE: '@@PAGE_RESULTS_EARNINGS/PAGE_RESULTS_EARNINGS_FAILURE',
}

export const pageResultsYearsConsts = {
  REQUEST: '@@PAGE_RESULTS_YEARS/PAGE_RESULTS_YEARS_REQUEST',
  SUCCESS: '@@PAGE_RESULTS_YEARS/PAGE_RESULTS_YEARS_SUCCESS',
  FAILURE: '@@PAGE_RESULTS_YEARS/PAGE_RESULTS_YEARS_FAILURE',
}

export const pageResultsTableConsts = {
  REQUEST: '@@PAGE_RESULTS_TABLE/PAGE_RESULTS_TABLE_REQUEST',
  SUCCESS: '@@PAGE_RESULTS_TABLE/PAGE_RESULTS_TABLE_SUCCESS',
  FAILURE: '@@PAGE_RESULTS_TABLE/PAGE_RESULTS_TABLE_FAILURE',
}

export const fetchResultsStats = () => ({
  [RSAA]: {
    endpoint: `/api/v2/race/stat`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageResultsStatsConsts.REQUEST,
      pageResultsStatsConsts.SUCCESS,
      pageResultsStatsConsts.FAILURE
    ]
  }
})

export const fetchResultsEarnings = () => ({
  [RSAA]: {
    endpoint: `/api/v2/race/summary`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageResultsEarningsConsts.REQUEST,
      pageResultsEarningsConsts.SUCCESS,
      pageResultsEarningsConsts.FAILURE
    ]
  }
})

export const fetchResultsYears = () => ({
  [RSAA]: {
    endpoint: `/api/v2/race/raceYear`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageResultsYearsConsts.REQUEST,
      pageResultsYearsConsts.SUCCESS,
      pageResultsYearsConsts.FAILURE
    ]
  }
})

export const fetchResultsTableByMonth = (year) => ({
  [RSAA]: {
    endpoint: `/api/v2/raceByMonth/${year}/desc/`,
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageResultsTableConsts.REQUEST,
      {
        type: pageResultsTableConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()

          let tableData = {}
          tableData.tableEntities = []

          payload.items.forEach((item) => {
            if (!item.raced || item.finishPlace === null) {
              return;
            }

            let entity = {
              year: item.raceDate.split('-')[0],
              main: {
                detailID: item.horseId,
                tableHeadings: [
                  item.horse, 'Date', 'Distance', 'Jockey', 'Finish', 'Race Condition', 'Trainer'
                ],
                tableCells: [
                  item.track,
                  moment(item.raceDate).format('MMM D[,] YYYY'),
                  item.distance,
                  item.jockey,
                  !item.finishPlace ? '-' : item.finishPlace,
                  item.condition,
                  item.trainer
                ]
              },
              details: {
                tableHeadings: [
                  'Comment', 'Race #', 'Surface', 'Post Race', 'Time', 'Earnings'
                ],
                tableCells: [
                  item.comment,
                  item.raceNumber,
                  item.surface,
                  item.postRaceDoc,
                  item.time,
                  item.earning
                ]
              }
            }
            tableData.tableEntities.push(entity)
          })
          
          return tableData
        }
      },
      pageResultsTableConsts.FAILURE
    ]
  }
})


