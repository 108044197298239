import React from 'react'

export default function PageContainer(props) {
  return (
    <main className={
      `${props.location.pathname.slice(1).split('/').length > 1 
        ? props.location.pathname.slice(1).split('/')[0]+'-'+props.location.pathname.slice(1).split('/')[1]
        : props.location.pathname.slice(1)}-page`
      }>
      {props.children}
    </main>
  )
}