import React from 'react'

const LogInSplash = (props) => {
  return (
    <section className="container-fluid login_splash-wrap">
      <div className="row justify-content-center justify-content-md-end login_splash">
        <div className="col max-w-480px">
          <h1 className="login_heading">{props.msg.items.length > 0 && props.msg.items[0].heading}</h1>
          <div className="login_body"
            dangerouslySetInnerHTML={{
              __html: props.msg.items.length > 0 && props.msg.items[0].body
            }}>
          </div>
          <div className="text-right">
            <button type="button" className="login_splash-btn"
              onClick={props.toggleLogInFormVisible}
            >Login</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogInSplash