import React from 'react';
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('authorization_token')
      ? <Component {...props} />
      : <Redirect to={`/login?referrer=${window.location.pathname}${window.location.search}`} />
  )} />
)

export default PrivateRoute