import { pageEventsConsts } from '../_actions/page-events.actions';

const initialState = {
  events: { needsInit: true }
}

export function pageEvents(state = initialState, action) {
  switch (action.type) {
    case pageEventsConsts.REQUEST:
      return {
        ...state,
        events: { loading: true }
      }
    case pageEventsConsts.SUCCESS:
      return {
        ...state,
        events: action.payload.items
      }
    case pageEventsConsts.FAILURE:
      return {
        ...state,
        events: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState  
    default:
      return state
  }
}