import { pageNewsArticleConsts } from '../_actions/page-newsarticle.actions';

const initialState = {
  newsArticle: { needsInit: true }
}

export function pageNewsArticle(state = initialState, action) {
  switch (action.type) {
    case pageNewsArticleConsts.REQUEST:
      return {
        ...state,
        newsArticle: { loading: true }
      }
    case pageNewsArticleConsts.SUCCESS:
      return {
        ...state,
        newsArticle: action.payload
      }
    case pageNewsArticleConsts.FAILURE:
      return {
        ...state,
        newsArticle: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState     
    default:
      return state
  }
}